import { useEffect, useRef, useState } from "react";
import CModal from "../../../../components/CModal";
import FModal from "../../../../components/FModal";
import InputLabel from "../../../../components/InputLabel";
import SModal from "../../../../components/SModal";
import environment from "../../../../services/environment";
import {
  axiosGetRequest,
  axiosPostRequest,
  post,
  put,
  remove,
} from "../../../../services/Request";
import { Formik } from "formik";
import { Input, NewECard } from "../../../../components";

import { useTranslation } from "react-i18next";
import "./entities.css";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router";
import Skeleton from "react-skeleton-loader";
import { programsIcon } from "../../../../assets/icons/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPen,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import HeaderWithBreadcrumbLayout from "../../../../layouts/headerWithBreadcrumbLayout";
import DrawerFilter from "../../../../components/DrawerFilter";
import { toast } from "react-toastify";
import { permissionsCheck } from "../../../../helpers/utils";
import NewTooltip from "../../../../components/NewTooltip";
import NewTable from "../../../../components/NewTable";
import TableSkeleton from "../../../../components/table/TableSkeleton";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import TableCardSwitch from "../../../../components/TableCardSwitch";
import AppButton from "../../../../components/buttons/appButton";
import { useQuery } from "@tanstack/react-query";
import NewSwitch from "../../../../components/NewSwitch";
import AppPagination from "../../../../components/Pagination";

const Entities = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const downloadExcel = useExcelExport();

  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [agency, setAgency] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const [perspectiveName, setPerspectiveName] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [page, setPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "5", value: 5 });

  const formRef = useRef(null);

  const handleSubmitAddAgency = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const formEditRef = useRef(null);

  const handleSubmitEditAgency = () => {
    if (formEditRef.current) {
      formEditRef.current?.handleSubmit();
    }
  };

  const {
    data: paginatedAgencies,
    isLoading: isGettingPaginatedAgencies,
    refetch: refetchPaginatedAgencies,
  } = useQuery({
    queryKey: ["paginatedAgencies", page, perspectiveName, maxResult.value],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgency, {
        skipCount: page * maxResult.value,
        maxResultCount: maxResult.value,
        keyword: perspectiveName,
      }),
    select: (res) => res?.result,
  });

  const { data: allAgencies } = useQuery({
    queryKey: ["allAgencies", "paginatedAgencies", perspectiveName],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgency, {
        skipCount: 0,
        maxResultCount: 999999,
        keyword: perspectiveName,
      }),
    select: (res) => res?.result?.items,
  });

  useEffect(() => {
    if (showTable) {
      setFilteredData(paginatedAgencies?.items);
    }
  }, [paginatedAgencies, showTable]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setPage(newOffset);
  };

  const handleFilterData = (value) => {
    if (!value) {
      setFilteredData(paginatedAgencies?.items);
    } else {
      const filtered = paginatedAgencies?.items?.filter((ele) => {
        const { nameInArabic, agencyCode, sector, isConfidentialAgency } = ele;
        return (
          nameInArabic?.includes(value) ||
          agencyCode?.toLowerCase().includes(value.toLowerCase()) ||
          sector?.toLowerCase().includes(value.toLowerCase()) ||
          ("جهة سرية".includes(value) && isConfidentialAgency) ||
          ("جهة غير سرية".includes(value) && !isConfidentialAgency)
        );
      });
      setFilteredData(filtered);
    }
  };

  const addAgency = (values, attachmentId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      name: values.title,
      nameInArabic: values.titleArabic,
    };
    post(environment.addAgency, data, config, (res) => {
      setSelectedFile("");
      if (res.status == 500) {
        toast.error(res.data?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.success(t("modules.main.users.entities.messages.addEntity"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpenAddModal(false);
      }
    });
  };

  const editAgency = (values, attachmentId) => {
    setEditLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: agency.id,
      name: values.title,
      nameInArabic: values.titleArabic,
    };
    put(environment.editAgency, data, config, (res) => {
      setEditLoading(false);
      if (res?.status == 200) {
        toast.success(t("modules.main.users.entities.messages.editEntity"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpenEditModal(false);
      }
    });
  };

  const deleteAgency = () => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteAgency + "?id=" + agency.id, config, (res) => {
      setDeleteLoading(false);
      if (res?.status == 200) {
        toast.success(t("modules.main.users.entities.messages.deleteEntity"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpenDelete(false);
      }
    });
  };

  const onFileUpload = (values, type) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();

    formData.append("Name", selectedFile.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", selectedFile);

    post(environment.attachmentFile, formData, config, (res) => {
      if (type == "add") {
        addAgency(values, res.data.result.id);
      } else if (type == "edit") {
        editAgency(
          values,
          selectedFile == "" ? agency.attachmentId : res.data.result.id
        );
      }
    });
  };

  const entitiesDetailsBreadcumbs = [
    {
      icon: programsIcon,
      title: "مستخدمو النظام",
      url: "/entities",
    },
    {
      title: "الجهات الحكومية",
      url: "/entities",
    },
  ];

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows(paginatedAgencies?.items?.map((row) => row.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelection = (row) => {
    if (selectedRows.includes(row.id)) {
      setSelectedRows(selectedRows.filter((id) => id !== row.id));
    } else {
      setSelectedRows([...selectedRows, row.id]);
    }
  };

  const handleAddReportToAgencies = async (
    showFinalReport = true,
    updateOnlySelectedAgencies = true
  ) => {
    const res = await axiosPostRequest(
      environment.GiveFinalReportPermissionToAgencies +
        `?updateOnlySelectedAgencies=${updateOnlySelectedAgencies}&showFinalReport=${showFinalReport}`,
      selectedRows
    );
    if (res?.success) {
      toast.success(
        showFinalReport
          ? "تم إضافة التقرير للجهات بنجاح"
          : "تم إزالة التقرير من الجهات بنجاح"
      );
      refetchPaginatedAgencies();
    } else {
      toast.error("حدث خطاء");
    }
  };

  const handleAddReportToAgency = async (agencyId, showFinalReport = true) => {
    const res = await axiosPostRequest(
      environment.GiveFinalReportPermissionToAgencies +
        `?updateOnlySelectedAgencies=true&showFinalReport=${showFinalReport}`,
      [agencyId]
    );
    if (res?.success) {
      toast.success(
        showFinalReport
          ? "تم إضافة التقرير للجهة بنجاح"
          : "تم حذف التقرير من الجهة بنجاح"
      );
      refetchPaginatedAgencies();
    } else {
      toast.error("حدث خطاء");
    }
  };

  const columns = [
    {
      name: (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
        </div>
      ),
      accessorKey: "select",
      selector: (row) => row.select,
      center: true,
      cell: (row) => (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectedRows.includes(row.id)}
            onChange={() => handleRowSelection(row)}
          />
        </div>
      ),
    },
    {
      name: "اسم الجهة",
      selector: (row) => row.nameInArabic,
      header: "nameInArabic",
      accessorKey: "nameInArabic",
      sortable: true,
      cell: (row) => row.nameInArabic || row.name || "-",
    },
    {
      name: "كود الجهة",
      selector: (row) => row.agencyCode,
      header: "agencyCode",
      accessorKey: "agencyCode",
      sortable: true,
      center: true,
      cell: (row) => row.agencyCode || "-",
    },
    {
      name: "نوع الجهة",
      selector: (row) => row.isConfidentialAgency,
      header: "isConfidentialAgency",
      accessorKey: "isConfidentialAgency",
      sortable: true,
      center: true,
      cell: (row) =>
        (row.isConfidentialAgency ? "جهة سرية" : "جهة غير سرية") || "-",
    },
    {
      name: "اسم القطاع",
      selector: (row) => row.sector,
      header: "sector",
      accessorKey: "sector",
      sortable: true,
      center: true,
      cell: (row) => <div className="text-center">{row.sector || "-"} </div>,
    },
    {
      name: "عرض التقرير النهائي",
      selector: (row) => row.showFinalReport,
      header: "showFinalReport",
      accessorKey: "showFinalReport",
      center: true,
      cell: (row) => (
        <div>
          <NewSwitch
            value={row.showFinalReport}
            onsubmit={() => handleAddReportToAgency(row.id, true)}
            onFalseClick={() => handleAddReportToAgency(row.id, false)}
            setValue={() => {}}
          />
        </div>
      ),
    },

    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      center: true,
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          permissionsCheck("Pages.UserType.Admin") && (
            <div className="flex gap-2">
              <NewTooltip content={"تعديل"}>
                <FontAwesomeIcon
                  icon={faPen}
                  className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
                  onClick={() => setAgency(row, setOpenEditModal(true))}
                />
              </NewTooltip>

              <NewTooltip content={"حذف"}>
                <FontAwesomeIcon
                  icon={faX}
                  onClick={() => setAgency(row, setOpenDelete(true))}
                  className="text-red-700 bg-rose-50 mr-1 rounded-full p-2 cursor-pointer"
                />
              </NewTooltip>
            </div>
          )
        );
      },
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={entitiesDetailsBreadcumbs}
      btnLabel={"إضافة جهة حكومية"}
      handleBtnClick={() => setOpenAddModal(true)}
      hideBtn={permissionsCheck("Pages.Agencies.Create") ? false : true}
    >
      <SModal
        type={""}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={""}
      />
      <CModal
        loading={deleteLoading}
        confirmModal={() => deleteAgency()}
        open={openDelete}
        setOpen={() => setOpenDelete(false)}
        subTitle={
          t("modules.main.users.entities.messages.confirmMessage") +
          " " +
          agency.name
        }
      />
      <FModal
        content={
          <div>
            <Formik
              innerRef={formRef}
              initialValues={{ title: "", titleArabic: "", attachmentId: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.title) {
                  errors.title = t(
                    "modules.main.users.entities.messages.titleEn"
                  );
                }
                if (/^[a-zA-Z\d ]*$/.test(values.title) == false) {
                  errors.title = t("هذا الحقل يقبل انجليزي فقط");
                }
                if (!values.titleArabic) {
                  errors.titleArabic = t(
                    "modules.main.users.entities.messages.titleAr"
                  );
                }

                if (
                  /^[\u0621-\u064A\u0660-\u0669\d ]*$/.test(
                    values.titleArabic
                  ) == false
                ) {
                  errors.titleArabic = t("هذا الحقل يقبل عربي فقط");
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                addAgency(values);
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <div>
                    <InputLabel
                      style={{
                        borderColor: errors.title
                          ? "red"
                          : values.title != ""
                          ? "#22bb33"
                          : "transparent",
                      }}
                      value={values.title}
                      onChange={handleChange}
                      name={"title"}
                      label={t("modules.main.users.entities.form.titleEn")}
                      placeholder={t("الاسم انجليزي")}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.title && touched.title && errors.title}
                    </div>
                    <InputLabel
                      style={{
                        borderColor: errors.titleArabic
                          ? "red"
                          : values.titleArabic != ""
                          ? "#22bb33"
                          : "transparent",
                      }}
                      value={values.titleArabic}
                      onChange={handleChange}
                      name={"titleArabic"}
                      label={t("modules.main.users.entities.form.titleAr")}
                      placeholder={t("الاسم عربي")}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.titleArabic &&
                        touched.titleArabic &&
                        errors.titleArabic}
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        }
        open={openAddModal}
        titleButton={t("modules.main.users.entities.titleAddButton")}
        widthButton={false}
        action={handleSubmitAddAgency}
        type={"submit"}
        width={160}
        setOpen={() => setOpenAddModal(false)}
        headerTitle={t("modules.main.users.entities.titleAddButton")}
      />
      <FModal
        content={
          <div>
            <Formik
              innerRef={formEditRef}
              initialValues={{
                title: agency.name,
                titleArabic: agency.nameInArabic,
                attachmentId: agency.attachmentId,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.title) {
                  errors.title = t(
                    "modules.main.users.entities.messages.titleEn"
                  );
                }
                if (!values.title.replace(/[^A-Za-z]/gi, "")) {
                  errors.title = t("هذا الحقل يقبل انجليزي فقط");
                }
                if (!values.titleArabic) {
                  errors.titleArabic = t(
                    "modules.main.users.entities.messages.titleAr"
                  );
                }
                if (!values.titleArabic.replace(/[^ا-ي]/gi, "")) {
                  errors.titleArabic = t("هذا الحقل يقبل عربي فقط");
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                onFileUpload(values, "edit");
                editAgency(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <InputLabel
                    style={{
                      borderColor: errors.title
                        ? "red"
                        : values.title != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                    value={values.title}
                    onChange={handleChange}
                    name={"title"}
                    label={t("modules.main.users.entities.form.titleEn")}
                    placeholder={t(
                      "modules.main.users.entities.form.placeholderTitleEn"
                    )}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.title && touched.title && errors.title}
                  </div>
                  <InputLabel
                    style={{
                      borderColor: errors.titleArabic
                        ? "red"
                        : values.titleArabic != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                    value={values.titleArabic}
                    onChange={handleChange}
                    name={"titleArabic"}
                    label={t("modules.main.users.entities.form.titleAr")}
                    placeholder={t(
                      "modules.main.users.entities.form.placeholderTitleAr"
                    )}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.titleArabic &&
                      touched.titleArabic &&
                      errors.titleArabic}
                  </div>
                </div>
              )}
            </Formik>
          </div>
        }
        loading={editLoading}
        open={openEditModal}
        titleButton={t("modules.main.users.entities.titleEditButton")}
        widthButton={false}
        width={160}
        action={handleSubmitEditAgency}
        type={"submit"}
        setOpen={() => setOpenEditModal(false)}
        headerTitle={t("modules.main.users.entities.titleEditButton")}
      />

      <div className="my-5 mb-10 flex justify-end">
        <DrawerFilter
          handleSearch={refetchPaginatedAgencies}
          handleClear={() => setPerspectiveName("")}
        >
          <div className="w-full">
            <Input
              containerStyle={{
                marginTop: "-5px",
              }}
              withLabel={true}
              style={{
                backgroundColor: "white",
                border: "1px solid #A3AED0",
              }}
              type={"text"}
              className={""}
              value={perspectiveName}
              onChange={(e) => setPerspectiveName(e.target.value)}
              label={"اسم الجهة"}
              placeholder={"اسم الجهة"}
            />
          </div>
        </DrawerFilter>
      </div>
      {permissionsCheck("Pages.Agencies.GetAll") ? (
        <div>
          <div className="flex flex-row justify-end mb-5 items-center">
            {/* <h3 className="my-8 mx-1">{totalCounts} عنصر بالقائمة</h3> */}
            <TableCardSwitch
              showTable={showTable}
              setShowTable={setShowTable}
            />
          </div>

          <div className="my-5 bg-white rounded-xl">
            {isGettingPaginatedAgencies ? (
              showTable ? (
                <TableSkeleton />
              ) : (
                <div className="grid my-5 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                  {paginatedAgencies?.items?.map((entity, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-between border-[1px] border-[#7D8BAB38] rounded-xl text-start p-5 m-1"
                    >
                      <Skeleton
                        animated={true}
                        width={"40%"}
                        height={"20px"}
                        count={1}
                      />
                      <Skeleton
                        animated={true}
                        width={"70%"}
                        height={"20px"}
                        count={1}
                      />
                      <div className="flex justify-between w-[100%] mt-5">
                        <Skeleton
                          animated={true}
                          width={"50px"}
                          height={"20px"}
                          count={1}
                        />
                        <div className="flex gap-3">
                          <Skeleton
                            animated={true}
                            width={"30px"}
                            height={"20px"}
                            count={1}
                          />
                          <Skeleton
                            animated={true}
                            width={"30px"}
                            height={"20px"}
                            count={1}
                          />
                          <Skeleton
                            animated={true}
                            width={"30px"}
                            height={"20px"}
                            count={1}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            ) : showTable ? (
              <div className="relative">
                {selectedRows.length > 0 && (
                  <div className="absolute top-3 left-60 flex gap-5">
                    <AppButton onClick={() => handleAddReportToAgencies(true)}>
                      إضافة التقرير للجهات المحددة
                    </AppButton>
                    <AppButton
                      onClick={() => handleAddReportToAgencies(false)}
                      variant="danger"
                    >
                      إزالة التقرير للجهات المحددة
                    </AppButton>
                  </div>
                )}
                <NewTable
                  columns={columns}
                  data={filteredData}
                  pagination={false}
                  withMaxResultChange={true}
                  maxResultValue={maxResult}
                  setMaxResultValue={(val) => {
                    setMaxResult(val);
                    setPage(0);
                  }}
                  withExcel
                  withSearch
                  onExportExcel={() => {
                    downloadExcel("الجهات الحكومية", allAgencies);
                  }}
                  onSearch={handleFilterData}
                />
              </div>
            ) : (
              <div className="grid mb-5  gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 ">
                {paginatedAgencies?.items?.map((entity, index) => (
                  <NewECard
                    from={"agencies"}
                    goPageEntityDetails={() =>
                      history.push(`/entities/${entity.id}/details`)
                    }
                    getAgencies={() => refetchPaginatedAgencies()}
                    isConfidentialAgency={entity?.isConfidentialAgency}
                    entityId={entity?.id}
                    withIconsAction={true}
                    withTitle={true}
                    onClick={() =>
                      history.push({
                        pathname: `/entities/${entity.id}/details`,
                      })
                    }
                    key={index}
                    withImage={true}
                    items={[]}
                    openDelete={() => setAgency(entity, setOpenDelete(true))}
                    openEdit={() => setAgency(entity, setOpenEditModal(true))}
                    titleAr={entity?.nameInArabic}
                    titleEn={entity?.name}
                    image={entity?.attachmentUrl}
                  />
                ))}
              </div>
            )}

            <AppPagination
              currentPage={page}
              totalPages={Math.ceil(
                paginatedAgencies?.totalCount / maxResult.value
              )}
              handlePageClick={handlePageClick}
              loading={isGettingPaginatedAgencies}
            />
          </div>
        </div>
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {"ليس لديك صلاحية لهذا"}
        </div>
      )}
    </HeaderWithBreadcrumbLayout>
  );
};

export default Entities;
