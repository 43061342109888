import { Image, Page, StyleSheet } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";
import Slide6 from "../assets/Slide6.jpg";

export function Page6({ agencyTitle, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page size="A4" style={styles.imagePage}>
      <Image style={styles.image} src={Slide6} />
      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}
