import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";
import Evaluation from "../assets/evaluation.png";
import SlideEq from "../assets/SlideEq.png";


export function Page5({ perspectives, agencyTitle, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <>
      <Page size="A4" style={styles.imagePage}>
      <Image src={SlideEq} style={styles.image} />
      <Footer agencyTitle={agencyTitle} />
    </Page>
    </>
  );
}
