import { Image, Page, StyleSheet } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";
import Slide3 from "../assets/Slide3.png";

export function ThirdPage({ agencyTitle, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page size="A4" style={styles.imagePage}>
      <Image src={Slide3} style={styles.image} />
      <Footer agencyTitle={agencyTitle} page={3} />
    </Page>
  );
}
