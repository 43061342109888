import NewDashboard from "../modules/main/home/NewDashboard";

import {
  Accept,
  ActivityLog,
  AddPermission,
  AddPolicesAndConditions,
  AddTeam,
  AddVisit,
  AgencyAnswerReport,
  AllEntities,
  AssignAgencies,
  CardDetails,
  CardEvaluation,
  CategoriesList,
  CCalendar,
  CreativeCards,
  CreativeForms,
  CreativeModels,
  EditPermission,
  Entities,
  EntityPermissions,
  Faq,
  Forms,
  GroupsList,
  Identities,
  IndicatorsList,
  InnovativeFormIdeaMaturity,
  InnovativeFormIdeaTypes,
  Login,
  Mailbox,
  MeetingsList,
  NewMessage,
  NewNotification,
  NewTicket,
  Notifications,
  Permissions,
  PrioritiesList,
  PrioritiesSlaList,
  Reports,
  StandardRate,
  SurveyEditTemplate,
  TicketDetails,
  TicketSettings,
  TicketsList,
  UserDetails,
  Users,
  Visit,
  VisitsAndMeetings,
  VisitSettings,
} from "./Index";
import ClassificationsList from "../modules/main/features/serveySettings/classifications";
import ServeyDegreesList from "../modules/main/features/serveySettings/serveyDegrees";
import ServeyPhases from "../modules/main/features/serveySettings/phases";
import PolicesAndConditions from "../modules/main/features/serveySettings/policesAndConditions";
import UserPermissions from "../modules/main/features/permissions";
import Indicators from "../modules/main/features/indicators";
import IndicatorDetails from "../modules/main/features/indicators/indicatorDetails/indicatorDetails";
import PrespectivePage from "../modules/main/features/prespective";
import StanderdsPage from "../modules/main/features/standerds";
import InterlocutorPage from "../modules/main/features/interlocutor";
import SurveyTemplates from "../modules/main/features/serveySettings/surveyTemplates";
import StandardEntities from "../modules/main/features/standerds/standardEntities";
import ServeyInnovativeForm from "../modules/main/features/serveySettings/innovativeForm";
import SurveyAddTemplate from "../modules/main/features/serveySettings/surveyTemplates/addTemplate";
import AddStandard from "../modules/main/features/standerds/addStandard";
import SurveyStrategy from "../modules/main/features/serveySettings/surveyStrategy";
import EntityDetailsIndex from "../modules/main/users/entities";
import DetailsUpdatePage from "../modules/main/users/entities/DetailsUpdatePage";
import AgencySettings from "../modules/main/settings/AgencySettings";
// import NewTicketSettings from "../modules/main/settings/new-ticket/NewTicketSettings";
import PreviousSurveys from "../modules/main/features/surveys/previousSurveys";
import StandardAnswerProgram from "../modules/main/features/standerds/standardAnswerProgram";
import EditStandardContainer from "../modules/main/features/standerds/editStandard";
import FQASettings from "../modules/main/settings/FQASettings";
import AgencyQuestions from "../modules/main/users/entities/AgencyQuestions";
import NotFound from "../services/NotFound";
import EditVisit from "../modules/main/visits/EditVisit";
import CheckRoles from "./checkRoles";
import NoPermission from "../modules/main/home/noPermission";
import VisitorsAnalytics from "../modules/main/visitorsAnalytics/VisitorsAnalytics";
import EntityAnswerAnalysis from "../modules/main/users/entities/entityAnswerAnalysis";
import PreviousAnswers from "../modules/main/features/surveys/previousSurveys/PreviousAnswers";
import NewStandardAnswer from "../modules/main/features/standerds/standardAnswer/standardAnswer";
import NewStandardAnswerProgram from "../modules/main/features/standerds/standardAnswer";
// import NewStandardAnswer from "../modules/main/features/standerds/standardAnswerProgram copy/standardAnswer";
import AgenciesAnswerForStandard from "../features/auditing/AgenciesAnswerForStandard";
import AgencyAnswerDetails from "../features/auditing/AgencyAnswerDetails";
import StandardsAudited from "../modules/main/features/standerds/standardsAudited";
import NoSurveyFound from "./NoSurveyFound";
import AnswersReport from "../modules/main/features/answersReport";
import Inquiries from "../modules/main/inquiries/Inquiries";
import CreativeModelsList from "../modules/main/creativeModels/CreativeModelsList";
import EditRequests from "../modules/main/editRequests/EditRequests";
import SelectedRequest from "../modules/main/editRequests/SelectedRequest";
import CurrentAnswers from "../modules/main/features/surveys/currentSurveys/CurrentAnswers";
import ComprehensiveReports from "../modules/main/comprehensiveReports";

export const routesLoggedOut = [
  {
    component: Login,
    path: "/login",
    exact: true,
  },
];

export const routes = [
  // all
  // {
  //   component: (
  //     <CheckRoles permission={["all"]}>
  //       <NoSurveyFound />
  //     </CheckRoles>
  //   ),
  //   permission: "",
  //   path: "/NoSurveyFound",
  //   exact: true,
  // },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <NewDashboard />
      </CheckRoles>
    ),
    permission: "",

    path: "/",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={["Pages.UserType.Admin", "Pages.UserType.ExpertManager"]}
      >
        <VisitorsAnalytics />
      </CheckRoles>
    ),
    permission: "",
    path: "/visitors/analytics",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AgenciesAnswerForStandard />
      </CheckRoles>
    ),
    permission: "",
    path: "/Standard/:standardId/agencies/answer",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AgenciesAnswerForStandard />
      </CheckRoles>
    ),
    permission: "",
    path: "/Standard/:standardId/agencies/answer/:stageId",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.UserType.Admin",
          "Pages.UserType.ExpertManager",
          "Pages.UserType.Expert",
          "Pages.UserType.Reviewer",
          "Pages.UserType.POCOfficer",
        ]}
      >
        <AgencyAnswerDetails />
      </CheckRoles>
    ),
    permission: "",
    path: "/Standard/:standardId/agency/:agencyId/answer-details",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <PreviousAnswers />
      </CheckRoles>
    ),
    permission: "",
    path: "/previousSurveys/:agencyId/download",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <CurrentAnswers />
      </CheckRoles>
    ),
    permission: "",
    path: "/currentSurveys/:agencyId/download",
    exact: true,
  },
  // agency
  {
    component: <Accept />,
    permission: "",
    path: "/survey/:surveyId/polices/accept",
    exact: true,
  },

  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Determinants",
          "Pages.Determinants.Create",
          "Pages.Determinants.Edit",
          "Pages.Determinants.Delete",
          "Pages.Determinants.Get",
          "Pages.Determinants.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <ClassificationsList />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Degrees",
          "Pages.Degrees.Create",
          "Pages.Degrees.Edit",
          "Pages.Degrees.Delete",
          "Pages.Degrees.Get",
          "Pages.Degrees.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <ServeyDegreesList />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/degrees",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <SurveyStrategy />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/strategy",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.NoteTemplats",
          "Pages.NoteTemplats.Create",
          "Pages.NoteTemplats.Edit",
          "Pages.NoteTemplats.Delete",
          "Pages.NoteTemplats.Get",
          "Pages.NoteTemplats.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <SurveyTemplates />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/templates",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.NoteTemplats",
          "Pages.NoteTemplats.Create",
          "Pages.NoteTemplats.Edit",
          "Pages.NoteTemplats.Delete",
          "Pages.NoteTemplats.Get",
          "Pages.NoteTemplats.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <SurveyAddTemplate />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/templates/add",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.NoteTemplats",
          "Pages.NoteTemplats.Create",
          "Pages.NoteTemplats.Edit",
          "Pages.NoteTemplats.Delete",
          "Pages.NoteTemplats.Get",
          "Pages.NoteTemplats.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <SurveyEditTemplate />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/templates/:id/edit",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <ServeyPhases />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/phases",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <PolicesAndConditions />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/polices",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <ServeyInnovativeForm />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/creative",
    exact: true,
  },

  //admin agency expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Perspectives",
          "Pages.Perspectives.Create",
          "Pages.Perspectives.Edit",
          "Pages.Perspectives.Delete",
          "Pages.Perspectives.Get",
          "Pages.Perspectives.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <PrespectivePage />
      </CheckRoles>
    ),
    permission: [
      "Pages.Perspectives",
      "Pages.Perspectives.Create",
      "Pages.Perspectives.Edit",
      "Pages.Perspectives.Delete",
      "Pages.Perspectives.Get",
      "Pages.Perspectives.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/programs/:surveyId/perspectives",
    exact: true,
  },
  //admin agency expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Interlocutors",
          "Pages.Interlocutors.Create",
          "Pages.Interlocutors.Edit",
          "Pages.Interlocutors.Delete",
          "Pages.Interlocutors.Get",
          "Pages.Interlocutors.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <InterlocutorPage />
      </CheckRoles>
    ),
    permission: [
      "Pages.Interlocutors",
      "Pages.Interlocutors.Create",
      "Pages.Interlocutors.Edit",
      "Pages.Interlocutors.Delete",
      "Pages.Interlocutors.Get",
      "Pages.Interlocutors.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/programs/:surveyId/perspectives/:perspectiveId/interlocutor",
    exact: true,
  },
  //aadmin agency expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Standards",
          "Pages.Standards.Create",
          "Pages.Standards.Edit",
          "Pages.Standards.Delete",
          "Pages.Standards.Get",
          "Pages.Standards.GetAll",
          "Pages.Standards.UpdateInquiryState",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <StanderdsPage />
      </CheckRoles>
    ),
    permission: [
      "Pages.Standards",
      "Pages.Standards.Create",
      "Pages.Standards.Edit",
      "Pages.Standards.Delete",
      "Pages.Standards.Get",
      "Pages.Standards.GetAll",
      "Pages.Standards.UpdateInquiryState",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path:
      "/programs/:surveyId/perspectives/:perspectiveId/interlocutor/:interlocutorId/standards",
    exact: true,
  },
  //admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AddStandard />
      </CheckRoles>
    ),
    permission: "",
    path:
      "/programs/:surveyId/perspectives/:perspectiveId/interlocutor/:interlocutorId/standards/add",
    exact: true,
  },
  //admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <EditStandardContainer />
      </CheckRoles>
    ),
    permission: "",
    path:
      "/programs/:surveyId/perspectives/:perspectiveId/interlocutor/:interlocutorId/standards/:standardId/edit",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <StandardEntities />
      </CheckRoles>
    ),
    permission: "",
    path:
      "/programs/:surveyId/perspectives/:perspectiveId/interlocutor/:interlocutorId/standards/:standardId/entites",
    exact: true,
  },
  // expert reviewer agency
  {
    component: (
      <CheckRoles permission={["all"]}>
        <StandardEntities />
      </CheckRoles>
    ),
    permission: "",
    path: "/programs/:surveyId/standards/:standardId/entites",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <StandardEntities />
      </CheckRoles>
    ),
    permission: "",
    path: "/programs/:surveyId/standard/:standardId/entities",
    exact: true,
  },
  // agency expert reviewer admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <StandardAnswerProgram />
      </CheckRoles>
    ),
    permission: "",
    path:
      "/programs/:programId/program/:entityId/entity/:standardId/standard/ss",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <NewStandardAnswerProgram />
      </CheckRoles>
    ),
    permission: "",
    path: "/programs/:programId/program/:entityId/entity/:standardId/standard",
    exact: true,
  },

  //admin expert
  {
    component: (
      <CheckRoles permission={["all"]}>
        <Inquiries />
      </CheckRoles>
    ),
    permission: "",
    path: "/inquiries",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <CCalendar />
      </CheckRoles>
    ),
    permission: "",
    path: "/calendar",
    exact: true,
  },
  //admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <Forms />
      </CheckRoles>
    ),
    permission: "",
    path: "/forms",
    exact: true,
  },
  //admin
  {
    component: (
      <CheckRoles
        permission={["Pages.Administration", "Pages.Administration.AuditLogs"]}
      >
        <ActivityLog />
      </CheckRoles>
    ),
    permission: ["Pages.Administration", "Pages.Administration.AuditLogs"],
    path: "/activity-logs",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={["all"]}
      >
        <AgencyAnswerReport />
      </CheckRoles>
    ),
    permission: "",
    path: "/agency-answer-report",
    exact: true,
  },
  // admin agency expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Notics",
          "Pages.Notics.Create",
          "Pages.Notics.Get",
          "Pages.Notics.GetAll",
          "Pages.Notics.Delete",
          "Pages.Notics.Edit",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Notifications />
      </CheckRoles>
    ),
    permission: [
      "Pages.Notics",
      "Pages.Notics.Create",
      "Pages.Notics.Get",
      "Pages.Notics.GetAll",
      "Pages.Notics.Delete",
      "Pages.Notics.Edit",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/notifications",
    exact: true,
  },

  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Roles",
          "Pages.Roles.Create",
          "Pages.Roles.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <AddPermission />
      </CheckRoles>
    ),
    permission: [
      "Pages.Roles",
      "Pages.Roles.Create",
      "Pages.Roles.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/permissions/add",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <EditPermission />
      </CheckRoles>
    ),
    permission: "",
    path: "/permissions/:id/edit",
    exact: true,
  },

  // agency admin
  {
    component: (
      <CheckRoles permission={["Pages.Agencies", "Pages.Agencies.Get"]}>
        <EntityDetailsIndex />
      </CheckRoles>
    ),
    permission: ["Pages.Agencies", "Pages.Agencies.Get"],
    path: "/entities/:id/details",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["Pages.Agencies", "Pages.Agencies.Get"]}>
        <EntityAnswerAnalysis />
      </CheckRoles>
    ),
    permission: ["Pages.Agencies", "Pages.Agencies.Get"],
    path: "/entities/:id/analysis",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <DetailsUpdatePage />
      </CheckRoles>
    ),
    permission: "",
    path: "/entities/:id/details-update",
    exact: true,
  },
  // agency
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AgencyQuestions />
      </CheckRoles>
    ),
    permission: "",
    path: "/entities/:id/agency-questions",
    exact: true,
  },

  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Agencies",
          "Pages.Agencies.Create",
          "Pages.Agencies.Edit",
          "Pages.Agencies.Delete",
          "Pages.Agencies.Get",
          "Pages.Agencies.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Entities />
      </CheckRoles>
    ),
    permission: [
      "Pages.Agencies",
      "Pages.Agencies.Create",
      "Pages.Agencies.Edit",
      "Pages.Agencies.Delete",
      "Pages.Agencies.Get",
      "Pages.Agencies.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/entities",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Roles",
          "Pages.Roles.Get",
          "Pages.Roles.Create",
          "Pages.Roles.Delete",
          "Pages.Roles.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <UserPermissions />
      </CheckRoles>
    ),
    permission: [
      "Pages.Roles",
      "Pages.Roles.Get",
      "Pages.Roles.Create",
      "Pages.Roles.Delete",
      "Pages.Roles.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/permissions/:id",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <Permissions />
      </CheckRoles>
    ),
    permission: "",
    path: "/permissions/ss",
    exact: true,
  },
  // admin agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Faq",
          "Pages.Faq.Get",
          "Pages.Faq.GetAll",
          "Pages.Faq.Create",
          "Pages.Faq.Delete",
          "Pages.Faq.Edit",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Faq />
      </CheckRoles>
    ),
    permission: [
      "Pages.Faq",
      "Pages.Faq.Get",
      "Pages.Faq.GetAll",
      "Pages.Faq.Create",
      "Pages.Faq.Delete",
      "Pages.Faq.Edit",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/faq",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          // "Pages.Faq"
          // "all",
          "Pages.SystemsUsers",
          "Pages.Administration.Users.Create",
          "Pages.Administration.Users.Edit",
          "Pages.Administration.Users.Delete",
          "Pages.Users",
          "Pages.Users.Get",
          "Pages.Users.Create",
          "Pages.Users.Edit",
          "Pages.Users.Delete",
          "Pages.Users.GetAll",
        ]}
      >
        <Users />
      </CheckRoles>
    ),
    permission: [
      // "Pages.Faq"

      // "all",
      "Pages.SystemsUsers",
      "Pages.Administration.Users.Create",
      "Pages.Administration.Users.Edit",
      "Pages.Administration.Users.Delete",
      "Pages.Users",
      "Pages.Users.Create",
      "Pages.Users.Edit",
      "Pages.Users.Delete",
      "Pages.Users.Get",
      "Pages.Users.GetAll",
    ],
    path: "/users",
    exact: true,
  },

  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.MailBox",
          "Pages.MailBox.Get",
          "Pages.MailBox.GetAll",
          "Pages.MailBox.Delete",
          "Pages.MailBox.Create",
          "Pages.MailBox.Replay",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Mailbox />
      </CheckRoles>
    ),
    permission: "",
    path: "/mailbox",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.MailBox",
          "Pages.MailBox.Get",
          "Pages.MailBox.GetAll",
          "Pages.MailBox.Delete",
          "Pages.MailBox.Create",
          "Pages.MailBox.Replay",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <NewMessage />
      </CheckRoles>
    ),
    permission: "",
    path: "/new-message",
    exact: true,
  },
  // admin expert agency
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AllEntities />
      </CheckRoles>
    ),
    permission: "",
    path: "/programs/:programId/standards",
    exact: true,
  },
  // expert manager
  {
    component: (
      <CheckRoles permission={["all"]}>
        <EditRequests />
      </CheckRoles>
    ),
    permission: "",
    path: "/programs/:programId/edit-requests",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <SelectedRequest />
      </CheckRoles>
    ),
    permission: "",
    path: "/programs/:programId/edit-requests/:requestId",
    exact: true,
  },

  // admin agency expert
  {
    component: (
      <CheckRoles permission={["all"]}>
        <MeetingsList />
      </CheckRoles>
    ),
    permission: "",
    path: "/meetings",
    exact: true,
  },
  // admin agency expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Tickets",
          "Pages.Tickets.Create",
          "Pages.Tickets.Edit",
          "Pages.Tickets.Delete",
          "Pages.Tickets.Get",
          "Pages.Tickets.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
          "Pages.Priority",
          "Pages.TicketsCategory",
          "Pages.Priority.GetAll",
          "Pages.Standards",
          "Pages.Standards.GetAll",
          "Pages.TicketsCategory.GetAll",
          "Pages.Agencies",
          "Pages.Agencies.GetAll",
        ]}
      >
        <TicketsList
          permission={[
            "Pages.Tickets",
            "Pages.Tickets.Create",
            "Pages.Tickets.Edit",
            "Pages.Tickets.Delete",
            "Pages.Tickets.Get",
            "Pages.Tickets.GetAll",
            "Pages.Users",
            "Pages.Users.Get",
            "Pages.Priority",
            "Pages.Standards",
            "Pages.Standards.GetAll",
            "Pages.Agencies",
            "Pages.Agencies.GetAll",
            "Pages.TicketsCategory",
            "Pages.Priority.GetAll",
            "Pages.TicketsCategory.GetAll",
          ]}
        />
      </CheckRoles>
    ),
    permission: [
      "Pages.Tickets",
      "Pages.Tickets.Create",
      "Pages.Tickets.Edit",
      "Pages.Tickets.Delete",
      "Pages.Tickets.Get",
      "Pages.Tickets.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
      "Pages.Priority.GetAll",
      "Pages.Priority",
      "Pages.Standards",
      "Pages.Standards.GetAll",
      "Pages.TicketsCategory",
      "Pages.TicketsCategory.GetAll",
      "Pages.Agencies",
      "Pages.Agencies.GetAll",
    ],
    path: "/tickets",
    exact: true,
  },
  //expert agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Tickets",
          "Pages.Tickets.Create",
          "Pages.Users",
          "Pages.Users.Get",
          "Pages.Priority",
          "Pages.TicketsCategory",
          "Pages.Priority.GetAll",
          "Pages.TicketsCategory.GetAll",
          "Pages.Standards",
          "Pages.Standards.GetAll",
        ]}
      >
        <NewTicket
          permission={[
            "Pages.Tickets",
            "Pages.Tickets.Create",
            "Pages.Users",
            "Pages.Users.Get",
            "Pages.Priority",
            "Pages.TicketsCategory",
            "Pages.Priority.GetAll",
            "Pages.TicketsCategory.GetAll",
            "Pages.Standards",
            "Pages.Standards.GetAll",
          ]}
        />
      </CheckRoles>
    ),
    permission: [
      "Pages.Tickets",
      "Pages.Tickets.Create",
      "Pages.Users",
      "Pages.Users.Get",
      "Pages.Priority",
      "Pages.TicketsCategory",
      "Pages.Priority.GetAll",
      "Pages.TicketsCategory.GetAll",
      "Pages.Standards",
      "Pages.Standards.GetAll",
    ],
    path: "/new-ticket",
    exact: true,
  },
  // admin expert agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Tickets",
          "Pages.Tickets.Get",
          "Pages.TicketHistory.Reply",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <TicketDetails
          permission={[
            "Pages.Tickets",
            "Pages.Tickets.Get",
            "Pages.TicketHistory.Reply",
            "Pages.Users",
            "Pages.Users.Get",
          ]}
        />
      </CheckRoles>
    ),
    permission: "",
    path: "/ticket/:id",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.TicketsCategory",
          "Pages.TicketsCategory.Create",
          "Pages.TicketsCategory.Edit",
          "Pages.TicketsCategory.Delete",
          "Pages.TicketsCategory.Get",
          "Pages.TicketsCategory.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <CategoriesList />
      </CheckRoles>
    ),
    permission: [
      "Pages.TicketsCategory",
      "Pages.TicketsCategory.Create",
      "Pages.TicketsCategory.Edit",
      "Pages.TicketsCategory.Delete",
      "Pages.TicketsCategory.Get",
      "Pages.TicketsCategory.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/tickets/categories",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Priority",
          "Pages.Priority.Create",
          "Pages.Priority.Edit",
          "Pages.Priority.Delete",
          "Pages.Priority.Get",
          "Pages.Priority.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <PrioritiesList />
      </CheckRoles>
    ),
    permission: [
      "Pages.Priority",
      "Pages.Priority.Create",
      "Pages.Priority.Edit",
      "Pages.Priority.Delete",
      "Pages.Priority.Get",
      "Pages.Priority.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/tickets/priorities",
    exact: true,
  },
  // agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Templates",
          "Pages.Templates.Create",
          "Pages.Templates.Edit",
          "Pages.Templates.Delete",
          "Pages.Templates.Get",
          "Pages.Users",
          "Pages.Users",
        ]}
      >
        <CreativeModels />
      </CheckRoles>
    ),
    permission: [
      "Pages.Templates",
      "Pages.Templates.Create",
      "Pages.Templates.Edit",
      "Pages.Templates.Delete",
      "Pages.Templates.Get",
      "Pages.Users",
      "Pages.Users",
    ],
    path: "/creativeModels",
    exact: true,
  },
  // agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormIdeaTypes",
          "Pages.InnovativeFormIdeaMaturities",
          "Pages.InnovativeFormAgencyAnswers",
          "Pages.InnovativeFormIdeaTypes.GetAll",
          "Pages.InnovativeFormIdeaMaturities.GetAll",
          "Pages.InnovativeFormAgencyAnswers.Create",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <CreativeForms />
      </CheckRoles>
    ),
    permission: "",
    path: "/creativeModels/form",
    exact: true,
  },
  // expert admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <CardEvaluation />
      </CheckRoles>
    ),
    permission: "",
    path: "/CardEvaluation",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <GroupsList />
      </CheckRoles>
    ),
    permission: "",
    path: "/tickets/groups",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <PrioritiesSlaList />
      </CheckRoles>
    ),
    permission: "",
    path: "/tickets/priorities/sla",
    exact: true,
  },

  // agency
  {
    component: (
      <CheckRoles permission={["all"]}>
        <CreativeModels />
      </CheckRoles>
    ),
    permission: "",
    path: "/creativeModels",
    exact: true,
  },
  // agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormIdeaTypes",
          "Pages.InnovativeFormIdeaMaturities",
          "Pages.InnovativeFormAgencyAnswers",
          "Pages.InnovativeFormIdeaTypes.GetAll",
          "Pages.InnovativeFormIdeaMaturities.GetAll",
          "Pages.InnovativeFormAgencyAnswers.Create",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <CreativeForms />
      </CheckRoles>
    ),
    permission: "",
    path: "/creativeModels/form",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormIdeaTypes",
          "Pages.InnovativeFormIdeaMaturities",
          "Pages.InnovativeFormAgencyAnswers",
          "Pages.InnovativeFormIdeaTypes.GetAll",
          "Pages.InnovativeFormIdeaMaturities.GetAll",
          "Pages.InnovativeFormAgencyAnswers.Create",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <CreativeModelsList />
      </CheckRoles>
    ),
    permission: "",
    path: "/creativeModelsList",
    exact: true,
  },
  //agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormAgencyAnswers",
          "Pages.InnovativeFormAgencyAnswers.Edit",
          "Pages.InnovativeFormAgencyAnswers.Delete",
          "Pages.InnovativeFormAgencyAnswers.Get",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <CardDetails />
      </CheckRoles>
    ),
    permission: "",
    path: "/creativeCards/:id/details",
    exact: true,
  },
  // admin expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormIdeaMaturities",
          "Pages.InnovativeFormIdeaTypes",
          "Pages.InnovativeFormAgencyAnswerCorrections",
          "Pages.InnovativeFormIdeaMaturities.GetAll",
          "Pages.InnovativeFormIdeaTypes.GetAll",
          "Pages.InnovativeFormAgencyAnswerCorrections.Create",
          "Pages.InnovativeFormAgencyAnswerCorrections.Edit",
          "Pages.InnovativeFormAgencyAnswerCorrections.Delete",
          "Pages.InnovativeFormAgencyAnswerCorrections.Get",
          "Pages.InnovativeFormAgencyAnswerCorrections.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <CardEvaluation />
      </CheckRoles>
    ),
    permission: "",
    path: "/creativeCards/:id/evaluation",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormIdeaTypes",
          "Pages.InnovativeFormIdeaTypes.Create",
          "Pages.InnovativeFormIdeaTypes.Edit",
          "Pages.InnovativeFormIdeaTypes.Delete",
          "Pages.InnovativeFormIdeaTypes.Get",
          "Pages.InnovativeFormIdeaTypes.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <InnovativeFormIdeaTypes />
      </CheckRoles>
    ),
    permission: "",
    path: "/innovativeFormIdeaTypes",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormIdeaMaturities",
          "Pages.InnovativeFormIdeaMaturities.Create",
          "Pages.InnovativeFormIdeaMaturities.Edit",
          "Pages.InnovativeFormIdeaMaturities.Delete",
          "Pages.InnovativeFormIdeaMaturities.Get",
          "Pages.InnovativeFormIdeaMaturities.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <InnovativeFormIdeaMaturity />
      </CheckRoles>
    ),
    permission: "",
    path: "/innovativeFormIdeaMaturity",
    exact: true,
  },
  // agency
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.InnovativeFormAgencyAnswers",
          "Pages.InnovativeFormAgencyAnswers.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <CreativeCards />
      </CheckRoles>
    ),
    permission: "",
    path: "/creativeCards",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.TicketsCategory",
          "Pages.TicketsCategory.GetAll",
          "Pages.TicketsCategory.Create",
          "Pages.Priority",
          "Pages.Priority.Create",
          "Pages.Priority.GetAll",
          "Pages.SLA",
          "Pages.SLA.Create",
          "Pages.SLA.GetAll",
          "Pages.Groups",
          "Pages.Groups.Create",
          "Pages.Groups.GetAll",
        ]}
      >
        <TicketSettings />
      </CheckRoles>
    ),
    permission: [
      "Pages.TicketsCategory",
      "Pages.TicketsCategory.GetAll",
      "Pages.TicketsCategory.Create",
      "Pages.Priority",
      "Pages.Priority.Create",
      "Pages.Priority.GetAll",
      "Pages.SLA",
      "Pages.SLA.Create",
      "Pages.SLA.GetAll",
      "Pages.Groups",
      "Pages.Groups.Create",
      "Pages.Groups.GetAll",
    ],
    path: "/tickets/TicketSettings",
    exact: true,
  },
  // admin agency expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.VisitSlots",
          "Pages.VisitSlots.Create",
          "Pages.VisitSlots.Edit",
          "Pages.VisitSlots.Delete",
          "Pages.VisitSlots.Get",
          "Pages.VisitSlots.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Visit />
      </CheckRoles>
    ),
    permission: [
      "Pages.VisitSlots",
      "Pages.VisitSlots.Create",
      "Pages.VisitSlots.Edit",
      "Pages.VisitSlots.Delete",
      "Pages.VisitSlots.Get",
      "Pages.VisitSlots.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/visit",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.VisitSlots",
          "Pages.VisitSlots.Create",
          "Pages.VisitSlots.Edit",
          "Pages.VisitSlots.Delete",
          "Pages.VisitSlots.Get",
          "Pages.VisitSlots.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <AddVisit />
      </CheckRoles>
    ),
    permission: [
      "Pages.VisitSlots",
      "Pages.VisitSlots.Create",
      "Pages.VisitSlots.Edit",
      "Pages.VisitSlots.Delete",
      "Pages.VisitSlots.Get",
      "Pages.VisitSlots.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/visit/addVisit",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.VisitSlots",
          "Pages.VisitSlots.Edit",
          "Pages.VisitSlots.Get",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <EditVisit />
      </CheckRoles>
    ),
    permission: [
      "Pages.VisitSlots",
      "Pages.VisitSlots.Edit",
      "Pages.VisitSlots.Get",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/visit/editVisit",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.VisitTeam",
          "Pages.VisitTeam.Create",
          "Pages.VisitTeam.Edit",
          "Pages.VisitTeam.Delete",
          "Pages.VisitTeam.Get",
          "Pages.VisitTeam.GetAll",
          "Pages.MeetingAndVisitGoal",
          "Pages.MeetingAndVisitGoal.Create",
          "Pages.MeetingAndVisitGoal.Edit",
          "Pages.MeetingAndVisitGoal.Delete",
          "Pages.MeetingAndVisitGoal.GetAll",
          "Pages.MeetingAndVisitGoal.Get",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <VisitSettings />
      </CheckRoles>
    ),
    permission: [
      "Pages.VisitTeam",
      "Pages.VisitTeam.Create",
      "Pages.VisitTeam.Edit",
      "Pages.VisitTeam.Delete",
      "Pages.VisitTeam.Get",
      "Pages.VisitTeam.GetAll",
      "Pages.MeetingAndVisitGoal",
      "Pages.MeetingAndVisitGoal.Create",
      "Pages.MeetingAndVisitGoal.Edit",
      "Pages.MeetingAndVisitGoal.Delete",
      "Pages.MeetingAndVisitGoal.GetAll",
      "Pages.MeetingAndVisitGoal.Get",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/visit/settings",
    exact: true,
  },
  // admin expert agency
  {
    component: (
      <CheckRoles permission={["all"]}>
        <PreviousSurveys />
      </CheckRoles>
    ),
    permission: ["Admin", "Expert", "POC", "CIO", "Delegate"],
    path: "/previousSurveys",
    exact: true,
  },

  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.VisitTeam",
          "Pages.VisitTeam.Create",
          "Pages.VisitTeam.Edit",
          "Pages.VisitTeam.Delete",
          "Pages.VisitTeam.Get",
          "Pages.VisitTeam.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <AddTeam />
      </CheckRoles>
    ),
    permission: [
      "Pages.VisitTeam",
      "Pages.VisitTeam.Create",
      "Pages.VisitTeam.Edit",
      "Pages.VisitTeam.Delete",
      "Pages.VisitTeam.Get",
      "Pages.VisitTeam.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/visit/addTeam",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.AgencySectors",
          "Pages.AgencyClassifications",
          "Pages.Regions",
          "Pages.AgencySectors.Create",
          "Pages.AgencySectors.Edit",
          "Pages.AgencySectors.Delete",
          "Pages.AgencySectors.Get",
          "Pages.AgencySectors.GetAll",
          "Pages.AgencyClassifications.Create",
          "Pages.AgencyClassifications.Edit",
          "Pages.AgencyClassifications.Delete",
          "Pages.AgencyClassifications.Get",
          "Pages.AgencyClassifications.GetAll",
          "Pages.Regions.Create",
          "Pages.Regions.Edit",
          "Pages.Regions.Delete",
          "Pages.Regions.Get",
          "Pages.Regions.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <AgencySettings />
      </CheckRoles>
    ),
    permission: [
      "Pages.AgencySectors",
      "Pages.AgencyClassifications",
      "Pages.Regions",
      "Pages.AgencySectors.Create",
      "Pages.AgencySectors.Edit",
      "Pages.AgencySectors.Delete",
      "Pages.AgencySectors.Get",
      "Pages.AgencySectors.GetAll",
      "Pages.AgencyClassifications.Create",
      "Pages.AgencyClassifications.Edit",
      "Pages.AgencyClassifications.Delete",
      "Pages.AgencyClassifications.Get",
      "Pages.AgencyClassifications.GetAll",
      "Pages.Regions.Create",
      "Pages.Regions.Edit",
      "Pages.Regions.Delete",
      "Pages.Regions.Get",
      "Pages.Regions.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/visit/AgenciesCategories",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Notics",
          "Pages.Notics.Create",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <NewNotification />
      </CheckRoles>
    ),
    permission: [
      "Pages.Notics",
      "Pages.Notics.Create",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/notifications/NewNotification",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <IndicatorsList />
      </CheckRoles>
    ),
    permission: "",
    path: "/indicators/ss",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Indicators",
          "Pages.Indicators.Create",
          "Pages.Indicators.Edit",
          "Pages.Indicators.Delete",
          "Pages.Indicators.Get",
          "Pages.Indicators.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Indicators />
      </CheckRoles>
    ),
    permission: [
      "Pages.Indicators",
      "Pages.Indicators.Create",
      "Pages.Indicators.Edit",
      "Pages.Indicators.Delete",
      "Pages.Indicators.Get",
      "Pages.Indicators.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/indicators",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Templates",
          "Pages.Indicators",
          "Pages.Templates.Create",
          "Pages.Indicators.Get",
          "Pages.Templates.Edit",
          "Pages.Templates.Delete",
          "Pages.Templates.Get",
          "Pages.Templates.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <IndicatorDetails />
      </CheckRoles>
    ),
    permission: "",
    path: "/indicators/:indicatorId",
    exact: true,
  },
  // agency expert admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.VisitSlots",
          "Pages.VisitSlots.Create",
          "Pages.VisitSlots.Edit",
          "Pages.VisitSlots.Delete",
          "Pages.VisitSlots.Get",
          "Pages.VisitSlots.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <VisitsAndMeetings />
      </CheckRoles>
    ),
    permission: [
      "Pages.VisitSlots",
      "Pages.VisitSlots.Create",
      "Pages.VisitSlots.Edit",
      "Pages.VisitSlots.Delete",
      "Pages.VisitSlots.Get",
      "Pages.VisitSlots.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/visitsAndMeetings",
    exact: true,
  },
  // expert
  {
    component: (
      <CheckRoles permission={["all"]}>
        <StandardRate />
      </CheckRoles>
    ),
    permission: "",
    path: "/visit/:visitId/standard/:id/rate",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Agencies",
          "Pages.Agencies.Get",
          "Pages.Agencies.GetAll",
          "Pages.Standards.GetAll",
          "Pages.Users",
          "Pages.Users.GetAll",
          "Pages.Users.Get",
          "Pages.Administration.Users.Get",
          "Pages.Administration.Users.GetAll",
          "Pages.Agencies.Standardpermissions",
        ]}
      >
        <EntityPermissions />
      </CheckRoles>
    ),
    permission: [
      "Pages.Agencies",
      "Pages.Agencies.Get",
      "Pages.Agencies.GetAll",
      "Pages.Standards.GetAll",
      "Pages.Users",
      "Pages.Users.GetAll",
      "Pages.Users.Get",
      "Pages.Administration.Users.Get",
      "Pages.Administration.Users.GetAll",
      "Pages.Agencies.Standardpermissions",
    ],
    path: "/entities/:id/permissions",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AddPolicesAndConditions />
      </CheckRoles>
    ),
    permission: "",
    path: "/surveys/:surveyId/settings/polices/add",
    exact: true,
  },
  // admin agency expert
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Administration.Users",
          "Pages.Administration.Users.Get",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <UserDetails />
      </CheckRoles>
    ),
    permission: [
      "Pages.Administration.Users",
      "Pages.Administration.Users.Get",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/users/:userId/details",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.FaqSettings",
          "Pages.FaqSettings.Get",
          "Pages.FaqSettings.Create",
          "Pages.FaqSettings.Delete",
          "Pages.FaqSettings.Edit",
          "Pages.FaqSettings.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <FQASettings />
      </CheckRoles>
    ),
    permission: [
      "Pages.FaqSettings",
      "Pages.FaqSettings.Get",
      "Pages.FaqSettings.Create",
      "Pages.FaqSettings.Delete",
      "Pages.FaqSettings.Edit",
      "Pages.FaqSettings.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/FQA-settings",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Reports",
          "Pages.Reports.Get",
          "Pages.Reports.Create",
          "Pages.Reports.Delete",
          "Pages.Reports.Edit",
          "Pages.Reports.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Reports />
      </CheckRoles>
    ),
    permission: [
      "Pages.Reports",
      "Pages.Reports.Get",
      "Pages.Reports.Create",
      "Pages.Reports.Delete",
      "Pages.Reports.Edit",
      "Pages.Reports.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/reports",
    exact: true,
  },
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Reports",
          "Pages.Reports.Get",
          "Pages.Reports.Create",
          "Pages.Reports.Delete",
          "Pages.Reports.Edit",
          "Pages.Reports.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <ComprehensiveReports />
      </CheckRoles>
    ),
    permission: [
      "Pages.Reports",
      
      "Pages.Reports.Get",
      "Pages.Reports.Create",
      "Pages.Reports.Delete",
      "Pages.Reports.Edit",
      "Pages.Reports.GetAll",
      "Pages.Users",
      "Pages.Users.Get",
    ],
    path: "/comprehensive-reports",
    exact: true,
  },
  // admin
  {
    component: (
      <CheckRoles
        permission={[
          "Pages.Identities",
          "Pages.Identities.Create",
          "Pages.Identities.Edit",
          "Pages.Identities.Delete",
          "Pages.Identities.Get",
          "Pages.Identities.GetAll",
          "Pages.Users",
          "Pages.Users.Get",
        ]}
      >
        <Identities
          permission={[
            "Pages.Identities",
            "Pages.Identities.Create",
            "Pages.Identities.Edit",
            "Pages.Identities.Delete",
            "Pages.Identities.Get",
            "Pages.Identities.GetAll",
            "Pages.Users",
            "Pages.Users.Get",
          ]}
        />
      </CheckRoles>
    ),
    permission: "",
    path: "/identities",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <NoSurveyFound />
      </CheckRoles>
    ),
    permission: "",
    path: "/NoSurveyFound",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <NotFound />
      </CheckRoles>
    ),
    permission: "",
    path: "/notFound",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <NoPermission />
      </CheckRoles>
    ),
    permission: "",
    path: "/noPermission",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AssignAgencies />
      </CheckRoles>
    ),
    permission: "",
    path: "/standard/:id/assign-agencies",
    exact: true,
  },
  {
    component: (
      <CheckRoles permission={["all"]}>
        <AnswersReport />
      </CheckRoles>
    ),
    permission: "",
    path: "/Answers-Report",
    exact: true,
  }
];
