import { useContext, useEffect, useState } from "react";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import SummaryAgencyAnswer from "./SummaryAgencyAnswer";
import useAxiosGetRequest from "../../../../../helpers/customHooks/useAxiosGetRequest";
import NewTable from "../../../../../components/NewTable";
import DownloadButton from "../../../../../components/DownloadButton";
import { DropdownMultiSelect } from "../../../../../components";
import useExcelExport from "../../../../../helpers/customHooks/useExcelExport";
import { isAgency } from "../../../../../helpers/isPermission";
import { useQuery } from "@tanstack/react-query";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DigitalTransformationReport } from "../../../agencyAnswerReport/DigitalTransformationReport";
import { useHistory } from "react-router";
import AppLoader from "../../../../../components/loader";
import NewTooltip from "../../../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import AppModal from "../../../../../components/Models/appModal";
import { StageContext } from "../../../../../contexts/stageContext";
import AgencyNoteTemplate from "../../standerds/standardAnswer/answers/Agency/agencyNoteTemplates";
import { answersBadges } from "./answersBadges";
import { resultBadges } from "./resultBadges";

const CurrentSurveys = () => {
  const surveyId = localStorage.getItem("surveyId");
  const agencyId = localStorage.getItem("agencyId");
  const history = useHistory();
  const { currentStage } = useContext(StageContext);

  const [selectedAgency, setSelectedAgency] = useState("");
  const [sortStandards, setSortStandards] = useState("asc");
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [standardId, setStandardId] = useState(null);

  const [getCurrentQiyas, currentQiyas, getOldQiyasLoading] =
    useAxiosGetRequest();
  const qiyasObj = currentQiyas?.result;

  const { data: perspectivesResponse, isLoading: isGettingPerspectives } =
    useQuery({
      queryKey: ["GetAgencyPerspectives", surveyId, agencyId, selectedAgency],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyPerspectives, {
          agencyId: selectedAgency?.value || agencyId,
          surveyId,
        }),
      enabled: !!(selectedAgency?.value && agencyId && surveyId),
    });

  const {
    data: perspectivesWithAllDetailsResponse,
    isLoading: isGettingPerspectivesWithAllDetails,
  } = useQuery({
    queryKey: [
      "GetAgencyPerspectivesWithAllDetails",
      surveyId,
      agencyId,
      selectedAgency,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAgencyPerspectivesWithAllDetails, {
        agencyId: selectedAgency?.value || agencyId,
        surveyId,
      }),
    enabled: !!(selectedAgency?.value && agencyId && surveyId),
  });

  const { data: agencyStandardsCountResponse } = useQuery({
    queryKey: [
      "GetAgencyStandardsCountByCommitmentLevel",
      surveyId,
      agencyId,
      selectedAgency,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAgencyStandardsCountByCommitmentLevel, {
        agencyId: selectedAgency?.value || agencyId,
        surveyId,
      }),
    select: (response) => {
      return response

      const result = [...response.result];
      const secondToLast = {
        ...result[result.length - 2],
        commitmentLevelColor: result[result.length - 1].commitmentLevelColor,
      };
      const last = {
        ...result[result.length - 1],
        commitmentLevelColor: result[result.length - 2].commitmentLevelColor,
      };
      result[result.length - 2] = last;
      result[result.length - 1] = secondToLast;
      return { ...response, result };
    },
    enabled: !!(selectedAgency?.value && agencyId && surveyId),
  });

  const { data: agencyResultsResponse, isLoading: isGettingAgencyResults } =
    useQuery({
      queryKey: ["GetAgencyResults", agencyId, selectedAgency],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyResults, {
          agencyId: selectedAgency?.value || agencyId,
        }),
      enabled: !!(selectedAgency?.value && agencyId),
    });

  const {
    data: agenciesByClassificationResponse,
    isLoading: isGettingAgenciesByClassification,
  } = useQuery({
    queryKey: ["GetAgenciesByClassification", agencyId, selectedAgency],
    queryFn: () =>
      axiosGetRequest(environment.GetAgenciesByClassification, {
        agencyId: selectedAgency?.value || agencyId,
      }),
    enabled: !!(selectedAgency?.value && agencyId),
  });

  const { data: agencyStandardsResponse, isLoading: isGettingAgencyStandards } =
    useQuery({
      queryKey: [
        "GetAgencyStandards",
        surveyId,
        agencyId,
        selectedAgency,
        sortStandards,
      ],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyStandards, {
          agencyId: selectedAgency?.value || agencyId,
          surveyId,
          OrderByStandardCode: sortStandards == "asc" ? 0 : 1,
        }),
      enabled: !!(selectedAgency?.value && agencyId && surveyId),
    });

  useEffect(() => {
    setFilteredPerspectives(perspectivesResponse?.result);
  }, [perspectivesResponse]);

  useEffect(() => {
    setFilteredInterlocutors(currentQiyas?.result?.interlocutors);
  }, [currentQiyas]);

  useEffect(() => {
    setFilteredStandards(agencyStandardsResponse?.result);
  }, [agencyStandardsResponse]);

  const [filteredPerspectives, setFilteredPerspectives] = useState(
    perspectivesResponse?.result || []
  );

  const [filteredInterlocutors, setFilteredInterlocutors] = useState(
    qiyasObj?.interlocutors || []
  );

  const [filteredStandards, setFilteredStandards] = useState(
    agencyStandardsResponse?.result || []
  );

  const getStatistics = async (id) => {
    if (id || agencyId != "null")
      getCurrentQiyas(environment.GetAgencyStatistics, {
        agencyId: id ? id : agencyId,
        surveyId,
      });
  };

  const getAllAgencies = async () => {
    const resp = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    const defaultAgency = resp?.result?.find(
      (agency) => agency.id === parseInt(agencyId)
    );

    if (defaultAgency) {
      setSelectedAgency({
        label: defaultAgency.nameInArabic,
        value: defaultAgency.id,
      });
    } else {
      setSelectedAgency({
        label: resp?.result?.[0].nameInArabic,
        value: resp?.result?.[0].id,
      });
    }
  };

  useEffect(() => {
    getAllAgencies();
  }, []);

  const [allAgenciesOptions, setAllAgenciesOptions] = useState([]);

  const handleChangeAgency = (selectedAgencyObj) => {
    setSelectedAgency(selectedAgencyObj || "");
  };

  const handleGetAllAgencies = async () => {
    const data = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );

    const agenciesOptions = data?.result?.map((item) => ({
      label: item.nameInArabic || "",
      value: item.id,
      name: item.name || "",
    }));

    const defaultAgency = agenciesOptions?.find(
      (agency) => agency.value === parseInt(agencyId)
    );

    if (defaultAgency) {
      setSelectedAgency(defaultAgency);
    } else {
      setSelectedAgency(agenciesOptions[0]);
    }

    setAllAgenciesOptions(agenciesOptions);
  };

  function handleSortStandards() {
    if (sortStandards === "asc") {
      setSortStandards("desc");
    } else {
      setSortStandards("asc");
    }
  }

  useEffect(() => {
    handleGetAllAgencies();
  }, []);

  // ------------------ Agencies --------------

  useEffect(() => {
    if (agencyId == "null") getStatistics(selectedAgency?.value);
    else {
      getStatistics(agencyId);
    }
  }, [selectedAgency?.value]);

  const phase2StageId =
    currentStage?.stageIds && currentStage?.stageIds[1] | undefined;
  const phase4StageId =
    currentStage?.stageIds && currentStage?.stageIds[3] | undefined;

  const { data: expertEvaluation = {} } = useQuery({
    queryKey: [
      environment.getStandardExpertAnswer,
      phase2StageId,
      standardId,
      selectedAgency?.value || agencyId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardExpertAnswer,
        {
          standardId,
          agencyId: selectedAgency?.value || agencyId,
          stageId: phase4StageId,
        },
        undefined,
        signal
      ),
    enabled: !!standardId,
  });

  const perspectivesDataForExcel = perspectivesResponse?.result?.map((ele) => {
    return {
      المنظور: ele.title,
      النتيجة: ele.result,
    };
  });

  const interlocutorsDataForExcel = qiyasObj?.interlocutors?.map((ele) => {
    return {
      المحور: ele.title,
      النتيجة: ele.result,
    };
  });

  const standardsDataForExcel = agencyStandardsResponse?.result?.map((ele) => {
    return {
      الكود: ele.standardCode,
      "إجابة الجهة": ele.answerTypeAgencyName,
      "مستوى الالتزام": ele.answerTypeExpertName,
      "ملاحظات الفريق الوطني": ele.noteForAgency.content,
    };
  });

  const perspectivesColumns = [
    {
      name: "المنظور",
      selector: (row) => row.title,
      header: "المنظور",
      accessorKey: "title",
      center: true,
    },
    {
      name: "النتيجة",
      selector: (row) => row.result,
      header: "النتيجة",
      accessorKey: "result",
      center: true,
      sortable: true,
      cell: (row) => resultBadges(row.resultTypeName, row.result.toFixed(2)),
    },
  ];

  const interlocutorsColumns = [
    {
      name: "المحور",
      selector: (row) => row.title,
      header: "المحور",
      accessorKey: "title",
      center: true,
    },
    {
      name: "النتيجة",
      selector: (row) => row.result,
      header: "النتيجة",
      accessorKey: "result",
      sortable: true,
      center: true,
      cell: (row) => resultBadges(row.resultTypeName, row.result.toFixed(2)),
    },
  ];

  const standardsColumns = [
    {
      name: "كود المعيار",
      selector: (row) => row.standardCode,
      header: "المعيار",
      accessorKey: "standardCode",
      center: true,
      sortable: true,
    },
    {
      name: "إجابة الجهة",
      selector: (row) => row.answerTypeAgencyName,
      header: "إجابة الجهة",
      accessorKey: "answerTypeAgencyName",
      center: true,
      cell: (row) => answersBadges(row.answerTypeAgencyName),
    },
    {
      name: "مستوى الالتزام",
      selector: (row) => row.answerTypeExpertName,
      header: "مستوى الالتزام",
      accessorKey: "answerTypeExpertName",
      center: true,
      cell: (row) => answersBadges(row.answerTypeExpertName),
    },
    {
      name: "ملاحظات الفريق الوطني",
      selector: (row) => row.noteForAgency.content,
      header: "ملاحظات الخبير",
      accessorKey: "noteForAgency",
      center: true,
      cell: (row) => (
        <div>
          {row.noteForAgency?.noteTemplate?.templateContent ? (
            <NewTooltip content={"عرض"}>
              <FontAwesomeIcon
                icon={faEye}
                className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
                onClick={() => {
                  setOpenNotesModal(true);
                  setStandardId(row.standardId);
                }}
              />
            </NewTooltip>
          ) : (
            "لا يوجد ملاحظات"
          )}
        </div>
      ),
    },
  ];

  // New Table //
  const downloadExcel = useExcelExport();

  // search
  const handleFilterPerspectives = (value) => {
    if (!value) {
      setFilteredPerspectives(perspectivesResponse?.result);
    } else {
      const filtered = perspectivesResponse?.result?.filter((ele) => {
        const { result, resultTypeName, title } = ele;
        return (
          title.includes(value) ||
          resultTypeName.includes(value) ||
          result.toString().includes(value)
        );
      });
      setFilteredPerspectives(filtered);
    }
  };

  const handleFilterInterlocutors = (value) => {
    if (!value) {
      setFilteredInterlocutors(qiyasObj?.interlocutors);
    } else {
      const filtered = qiyasObj?.interlocutors?.filter((ele) => {
        const { result, resultTypeName, title } = ele;
        return (
          title.includes(value) ||
          resultTypeName.includes(value) ||
          result.toString().includes(value)
        );
      });
      setFilteredInterlocutors(filtered);
    }
  };

  const handleFilterStandards = (value) => {
    if (!value) {
      setFilteredStandards(agencyStandardsResponse?.result);
    } else {
      const filtered = agencyStandardsResponse?.result?.filter((ele) => {
        const { answerTypeAgencyName, answerTypeExpertName, standardCode } =
          ele;
        return (
          answerTypeAgencyName.includes(value) ||
          answerTypeExpertName.includes(value) ||
          standardCode.includes(value)
        );
      });
      setFilteredStandards(filtered);
    }
  };

  return (
    <>
      {getOldQiyasLoading ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="flex-1">
              {!isAgency && (
                <>
                  <DropdownMultiSelect
                    placeholder={"اختر جهة حكومية"}
                    isClearable={true}
                    withoutLabel={false}
                    isSearchable={true}
                    value={selectedAgency}
                    handleChange={handleChangeAgency}
                    options={allAgenciesOptions || []}
                    isMultiple={false}
                    isRequired={false}
                    label={"اختر جهة حكومية"}
                  />
                </>
              )}
            </div>
            <div className="flex-1 flex justify-end gap-3 self-end">
              <DownloadButton
                onClick={() =>
                  history.push({
                    pathname: `/currentSurveys/${agencyId != "null" ? agencyId : selectedAgency?.value
                      }/download`,
                    state: { agencyName: selectedAgency?.label },
                  })
                }
              >
                {"تحميل ملفات قياس 2024"}
              </DownloadButton>
              <PDFDownloadLink
                document={
                  <DigitalTransformationReport
                    agencyTitle={selectedAgency?.label}
                    qiyasObj={qiyasObj}
                    agencyResults={agencyResultsResponse?.result}
                    agencyStandardsCount={agencyStandardsCountResponse?.result}
                    agenciesByClassification={
                      agenciesByClassificationResponse?.result
                    }
                    perspectivesWithAllDetails={
                      perspectivesWithAllDetailsResponse?.result
                    }
                  />
                }
                fileName={`التقرير النهائي لجهة ${selectedAgency?.label} 2024.pdf`}
              >
                {({ loading }) => (
                  <DownloadButton
                    loading={
                      loading ||
                      isGettingAgenciesByClassification ||
                      isGettingAgencyResults ||
                      isGettingPerspectivesWithAllDetails
                    }
                  >
                    تحميل التقرير النهائي (ملف PDf)
                  </DownloadButton>
                )}
              </PDFDownloadLink>
            </div>
          </div>

            <div>
            {!getOldQiyasLoading && (
              <SummaryAgencyAnswer
                agencyTitle={selectedAgency?.label}
                qiyasObj={qiyasObj}
                agencyStandardsCount={agencyStandardsCountResponse?.result}
                isGettingPerspectivesWithAllDetails={
                  isGettingPerspectivesWithAllDetails
                }
                perspectivesWithAllDetails={
                  perspectivesWithAllDetailsResponse?.result
                }
              />
            )}
            {!isAgency && (
              <>
                <h3 className="mt-20 -mb-10 text-center font-bold">
                  نتيجة المناظير
                </h3>
                <NewTable
                  withSearch={true}
                  withExcel={true}
                  onExportExcel={() =>
                    downloadExcel(
                      `تقرير المناظير القياس الحالي لجهة ${selectedAgency?.label}`,
                      perspectivesDataForExcel
                    )
                  }
                  onSearch={handleFilterPerspectives}
                  loading={isGettingPerspectives}
                  columns={perspectivesColumns}
                  data={filteredPerspectives}
                  pagination={false}
                  fixedHeader={true}
                />
                <h3 className="mt-20 -mb-10 text-center font-bold">
                  نتيجة المحاور
                </h3>
                <NewTable
                  withSearch={true}
                  withExcel={true}
                  onExportExcel={() =>
                    downloadExcel(
                      `تقرير المحاور القياس الحالي لجهة ${selectedAgency?.label}`,
                      interlocutorsDataForExcel
                    )
                  }
                  onSearch={handleFilterInterlocutors}
                  loading={getOldQiyasLoading}
                  columns={interlocutorsColumns}
                  data={filteredInterlocutors}
                  pagination={false}
                  fixedHeader={true}
                />
              </>
            )}

            <h3 className="mt-20 -mb-10 text-center font-bold">
              نتيجة المعايير
            </h3>
            <NewTable
              withSearch={true}
              withExcel={true}
              onExportExcel={() =>
                downloadExcel(
                  `تقرير المعايير القياس الحالي لجهة ${selectedAgency?.label}`,
                  standardsDataForExcel
                )
              }
              onSearch={handleFilterStandards}
              loading={isGettingAgencyStandards}
              columns={standardsColumns}
              data={filteredStandards}
              pagination={false}
              fixedHeader={true}
              handleSort={(col, order) => {
                if (col.accessorKey === "standardCode") {
                  handleSortStandards();
                }
              }}
            />
          </div>
        </div>
      )}

      <AppModal
        isFooter={false}
        headerTitle={"ملاحظات الفريق الوطنى لقياس"}
        open={openNotesModal}
        setOpen={setOpenNotesModal}
      >
        {/* <div
          className="text-xl h-full"
          dangerouslySetInnerHTML={{ __html: expertNotes }}
        ></div> */}

        <AgencyNoteTemplate
          expertEvaluation={expertEvaluation?.result}
          hideSubtitle
          fullWidth
        />
      </AppModal>
    </>
  );
};

export default CurrentSurveys;
