import { exclamation } from "../../../../../assets/icons/Index";
import TopContent from "./TopContent";
import BottomContent from "./BottomContent";
import DigitalTransformationResult from "./DigitalTransformationResult";
import TableSkeleton from "../../../../../components/table/TableSkeleton";
import { numberToFixed } from "../../../../../helpers/utils";
import classNames from "classnames";
import { isAdmin, isAgency } from "../../../../../helpers/isPermission";

const commitmentLevels = [
  {
    "commitmentLevelId": 384,
    "commitmentLevelTitle": "التزام كلى",
    "commitmentLevelColor": "#1dc182",
    "standardCounts": 0,
    "asPercentage": 0,
    "fixedDegree": 0
  },
  {
    "commitmentLevelId": 385,
    "commitmentLevelTitle": "التزام جزئى",
    "commitmentLevelColor": "#ffa92b",
    "standardCounts": 0,
    "asPercentage": 0,
    "fixedDegree": 0
  },
  {
    "commitmentLevelId": 386,
    "commitmentLevelTitle": "عدم التزام",
    "commitmentLevelColor": "#f15768",
    "standardCounts": 0,
    "asPercentage": 0,
    "fixedDegree": 0
  },
  {
    "commitmentLevelId": 394,
    "commitmentLevelTitle": "لا ينطبق",
    "commitmentLevelColor": "#8b817b",
    "standardCounts": 0,
    "asPercentage": 0,
    "fixedDegree": 0
  },
  {
    "commitmentLevelId": 393,
    "commitmentLevelTitle": "لا يوجد اجابة",
    "commitmentLevelColor": "#000000",
    "standardCounts": 0,
    "asPercentage": 0,
    "fixedDegree": 0
  }
]

function SummaryAgencyAnswer({
  qiyasObj,
  agencyStandardsCount = [],
  agencyTitle = "",
  perspectivesWithAllDetails,
  isGettingPerspectivesWithAllDetails,
}) {
  // --------------------------- //
  const buttons = [
    {
      value: 5,
      title: "البناء",
      bg: "bg-[#b40000]",
      bgHover: "bg-[#810000]",
      rounded: "rounded-s-3xl",
      topContent:
        qiyasObj?.finalResult < 10 ? (
          <TopContent
            percentage={numberToFixed(qiyasObj?.finalResult)}
            iconColor={"text-[#b40000]"}
          />
        ) : null,

      bottomContent:
        !qiyasObj?.isFirstTimeInQiyas && qiyasObj?.previousFinalResult < 10 ? (
          <BottomContent
            percentage={numberToFixed(qiyasObj?.previousFinalResult)}
            iconColor={"text-[#b40000]"}
          />
        ) : null,
    },
    {
      value: 4,
      title: "الاٍتاحة",
      bg: "bg-[#ff6d00]",
      bgHover: "bg-[#c95802]",
      topContent:
        qiyasObj?.finalResult >= 10 && qiyasObj?.finalResult < 40 ? (
          <TopContent
            percentage={numberToFixed(qiyasObj?.finalResult)}
            iconColor={"text-[#ff6d00]"}
          />
        ) : null,

      bottomContent:
        !qiyasObj?.isFirstTimeInQiyas && qiyasObj?.previousFinalResult >= 10 &&
        qiyasObj?.previousFinalResult < 40 ? (
          <BottomContent
            percentage={numberToFixed(qiyasObj?.previousFinalResult)}
            iconColor={"text-[#ff6d00]"}
          />
        ) : null,
    },
    {
      value: 3,
      title: "التحسين",
      bg: "bg-[#ff9d00]",
      bgHover: "bg-[#ca7e03]",
      topContent:
        qiyasObj?.finalResult >= 40 && qiyasObj?.finalResult < 80 ? (
          <TopContent
            percentage={numberToFixed(qiyasObj?.finalResult)}
            iconColor={"text-[#ff9d00]"}
          />
        ) : null,

      bottomContent:
        !qiyasObj?.isFirstTimeInQiyas && qiyasObj?.previousFinalResult >= 40 &&
        qiyasObj?.previousFinalResult < 80 ? (
          <BottomContent
            percentage={numberToFixed(qiyasObj?.previousFinalResult)}
            iconColor={"text-[#ff9d00]"}
          />
        ) : null,
    },
    {
      value: 2,
      title: "التكامل",
      bg: "bg-[#1cc182]",
      bgHover: "bg-[#0e8b5b]",
      topContent:
        qiyasObj?.finalResult >= 80 && qiyasObj?.finalResult < 90 ? (
          <TopContent
            percentage={numberToFixed(qiyasObj?.finalResult)}
            iconColor={`text-[#1cc182]`}
          />
        ) : null,

      bottomContent:
        !qiyasObj?.isFirstTimeInQiyas && qiyasObj?.previousFinalResult >= 80 &&
        qiyasObj?.previousFinalResult < 90 ? (
          <BottomContent
            percentage={numberToFixed(qiyasObj?.previousFinalResult)}
            iconColor={`text-[#1cc182]`}
          />
        ) : null,
    },
    {
      value: 1,
      title: "الاٍبداع",
      bg: "bg-[#006633]",
      bgHover: "bg-[#004d26]",
      rounded: "rounded-e-3xl",
      topContent:
        qiyasObj?.finalResult >= 90 ? (
          <TopContent
            percentage={numberToFixed(qiyasObj?.finalResult)}
            iconColor={"text-[#006633]"}
          />
        ) : null,

      bottomContent:
        !qiyasObj?.isFirstTimeInQiyas && qiyasObj?.previousFinalResult >= 90 ? (
          <BottomContent
            percentage={numberToFixed(qiyasObj?.previousFinalResult)}
            iconColor={"text-[#006633]"}
          />
        ) : null,
    },
  ];

  return (
    <div className="p-5 m-10">
      <div className="flex flex-col gap-5">
        <h1 className="font-bold text-3xl text-[#26bf83]">ملخص نتيجة الجهة</h1>
        <p className="text-[#2a206a] text-xl ">
          نستعرض أدناه نتيجة التحول الرقمي لجهة
          {" " + agencyTitle + " "}
          {qiyasObj?.indicatorName} ونتيجة الالتزام بالمعايير الأساسية للتحول
          الرقمي 2024، ، وذلك استنادا إلى بياناتهم المدخلة عبر نظام القياس.
        </p>
      </div>

      <div className="border-[0.05px] my-5 border-solid border-gray-200 w-full"></div>

      {isGettingPerspectivesWithAllDetails ? (
        <TableSkeleton />
      ) : (
        <DigitalTransformationResult
          perspectives={perspectivesWithAllDetails}
          qiyasObj={qiyasObj}
        />
      )}

      <div
        className={`border-[0.05px] my-5 border-solid border-gray-200 w-full`}
      ></div>

      <div className="flex flex-col gap-5 px-5 pb-16">
        <h1 className="font-bold text-2xl text-[#7c32c9]">النتيجة المكافئة</h1>

        <div className="w-full flex justify-center p-10 mt-16 ">
          <div className="inline-flex rounded-2xl shadow-sm" role="group">
            {buttons.map((ele) => (
              <>
                <button
                  type="button"
                  className={`relative px-10 py-2 pb-4 text-xl font-medium text-white ${ele.bg} ${ele.rounded} hover:${ele.bgHover} focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
                >
                  {ele.title}

                  {ele.topContent}
                  {ele.bottomContent}
                </button>
              </>
            ))}
          </div>
        </div>
      </div>

      <div
        className="border-[0.05px] my-5 border-solid border-gray-200 w-full"
        id="btnGroup"
      ></div>

      <div className="flex px-5 gap-16">
        <div className="flex flex-col w-fit">
          <h1 className="font-bold text-2xl whitespace-nowrap text-[#2a2069]">
            نتيجة الالتزام
          </h1>
          <div className="flex flex-1 justify-center items-center">
            <h2 className="font-bold  text-4xl text-[#2a2069]">{`${
              numberToFixed(qiyasObj?.finalResult) || 0
            }%`}</h2>
          </div>
        </div>

        <div className="w-full space-y-2">
          {commitmentLevels?.map((ele, index) => (
            <div className="flex gap-5 p-3 shadow-sm rounded-lg border-2 border-gray-200 w-full">
              <h1 className="font-bold text-xl text-[#2a2069] w-20">
                {numberToFixed(
                    agencyStandardsCount?.find(
                      (x) => x.commitmentLevelId === ele.commitmentLevelId
                    )?.asPercentage ||
                  ele.asPercentage
                  ) || 0}%
              </h1>
              <div className="flex flex-col gap-2 w-full">
                <div class="relative mt-2 h-4 bg-[#f2f2f2] rounded-full">
                  <div
                    class={`absolute h-full rounded-full`}
                    style={{
                      width: `${numberToFixed(
                        agencyStandardsCount?.find(
                          (x) => x.commitmentLevelId === ele.commitmentLevelId
                        )?.asPercentage || ele.asPercentage
                      )}%`,
                      backgroundColor:
                        agencyStandardsCount?.find(
                          (x) => x.commitmentLevelId === ele.commitmentLevelId
                        )?.commitmentLevelColor || ele.commitmentLevelColor,
                    }}
                  ></div>
                </div>
                <div className="flex justify-between px-1.5">
                  <p className="text-[#2a206a] text-base font-semibold ">
                    {
                      agencyStandardsCount?.find(
                        (x) => x.commitmentLevelId === ele.commitmentLevelId
                      )?.standardCounts || 0} معيار
                  </p>
                  <p className="text-[#2a206a] text-base">
                    {
                      agencyStandardsCount?.find(
                        (x) => x.commitmentLevelId === ele.commitmentLevelId
                      )?.commitmentLevelTitle}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="border-[0.05px] my-5 border-solid border-gray-200 w-full"></div>

      <div className={
        classNames(
          "gap-5 px-5",
          {
            "hidden": isAdmin || isAgency,
            "flex": !isAdmin && !isAgency,
          }
        )
      }>
        <div className="flex justify-center items-center p-5">
          <img src={exclamation} alt="exclamation" className="w-[100px]" />
        </div>

        <p className="text-[#2a206a] text-lg ">
          <span className="text-[#2a206a] text-lg font-semibold ">
            نتيجة الالتزام
          </span>{" "}
          هي نتيجة مبنية على التزام الجهة في مؤشر قياس للتحول الرقمي. ويتم
          حسابها بناء على نسبة متوسط الاٍجابات ما بين الالتزام الكلى والالتزام
          الجزئي وعدم الالتزام علما بأن النتيجة النهائية المعتمدة في قياس هي{" "}
          <span className="text-[#2a206a] text-lg font-semibold ">
            نتيحة التحول الرقمي
          </span>
        </p>
      </div>
    </div>
  );
}

export default SummaryAgencyAnswer;