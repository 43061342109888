import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Home, Login } from "./routes/Index";
import { routes } from "./routes/Routes";

import { GlobalProvider } from "./contexts/globalContext";
import RaqmiPage from "./modules/main/home/RaqmiPage";
// import BILayout from "./modules/bi/pages/Layout/Layout";
import * as signalR from "@microsoft/signalr";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useEffect } from "react";
import LoginWithNafath from "./modules/auth/LoginWithNafath";
import IndicatorAfterLogin from "./modules/main/home/IndicatorAfterLogin";
import NoSurveyFound from "./routes/NoSurveyFound";
import ProtectedRoute from "./routes/ProtectedRoute ";
import LogoutCountDown from "./components/logoutCountDown";
// import BILayout from "./modules/bi/pages/Layout/Layout";

const BILayout = React.lazy(() => import("./modules/bi/pages/Layout/Layout"));

const signalRConnect = async () => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(
      `${process.env.REACT_APP_URL}/dashboardReportHub`,
      signalR.HttpTransportType.LongPolling
    )
    .withAutomaticReconnect()
    .build();

  connection.start().then((res1) => {
    localStorage.setItem("connectionId", connection.connectionId);
    connection.on("ReceivingAnswerReports", (message) => {
      document.dispatchEvent(
        new CustomEvent("reportReady", { detail: message })
      );
    });
  });

  return connection;
};

function App() {
  useEffect(() => {
    const connection = signalRConnect();
    return () => {
      if (connection && typeof connection.stop === "function") {
        connection.stop();
      }
    };
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <GlobalProvider>
          <div className="flex w-[100%]">
            {window.location.pathname !== "/login" &&
            localStorage.getItem("accessToken") != null &&
            localStorage.getItem("surveyId") != null &&
            window.location.pathname !== "/ChooseCurruntIndicator" &&
            window.location.pathname !==
              `/survey/${localStorage.getItem("surveyId")}/polices/accept` &&
            window.location.pathname !== "/hello" &&
            window.location.pathname !== "/notFound" &&
            window.location.pathname !== "/NoSurveyFound" &&
            window.location.pathname !== "/login" ? (
              <Home />
            ) : null}
            <div
              className={
                window.location.pathname !== "/login" &&
                localStorage.getItem("accessToken") != null
                  ? window.location.pathname === "/ChooseCurruntIndicator" ||
                    window.location.pathname ===
                      `/survey/${localStorage.getItem(
                        "surveyId"
                      )}/polices/accept` ||
                    window.location.pathname === "/hello" ||
                    window.location.pathname === "/notFound" ||
                    window.location.pathname === "/qyias/auth/admin"
                    ? "w-[100%] h-[100vh] overflow-y-scroll"
                    : "w-[100%] h-[100vh] overflow-y-scroll bg-[#292069]"
                  : "w-[100%]"
              }
            >
              {!localStorage.getItem("accessToken") ? (
                <Switch>
                  <Route path="/bi">
                    <Suspense fallback={<p>قد يستغرق التحميل بعض الوقت</p>}>
                      <BILayout />
                    </Suspense>
                  </Route>
                  <Route path="/hello" component={LoginWithNafath} />
                  <Route path="/Nafath" component={RaqmiPage} />
                  
                  {process.env.REACT_APP_LOGIN_URL !== "NULL" && (
                    <Route
                      path="/8G7kJ2fL1pQxR9sD0wZ4bC6tN5vM8uY"
                      component={Login}
                    />
                  )}

                  <Redirect to={"/hello"} />
                </Switch>
              ) : !localStorage.getItem("surveyId") ? (
                <Switch>
                  <Route
                    path="/ChooseCurruntIndicator"
                    component={IndicatorAfterLogin}
                  />
                  <Route path="/NoSurveyFound" component={NoSurveyFound} />

                  <Redirect to={"/NoSurveyFound"} />
                </Switch>
              ) : (
                <>
                  <Switch>
                    {routes.map((route, index) => (
                      <ProtectedRoute
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    ))}
                    <Redirect to={"/"} />
                  </Switch>
                  <LogoutCountDown />
                </>
              )}
            </div>
          </div>
        </GlobalProvider>
        <ToastContainer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
