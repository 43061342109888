import AppButton from "../../../../../components/buttons/appButton";
import { axiosPutRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { produce } from "immer";
import { errorToast, successToast } from "../../../../../helpers/toast";
import { AddStandardContext } from "../addStandard/addStandardContext";

const SubmitEditStandard = ({disabled}) => {
  const { interlocutorId, standardId, surveyId, perspectiveId } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const {
    addFinalValue,
    setAddFinalValue,
    subRequirementCount,
    selectedRequirementAgencies,
  } = useContext(AddStandardContext);
  useEffect(() => {
    setAddFinalValue(
      produce((draft) => {
        draft.interlocutorId = Number(interlocutorId);
        draft.id = Number(standardId);
      })
    );
  }, []);


  const handleSubmit = async () => {
    const standardRequirements = [
      ...addFinalValue?.standardRequirements,
    ]?.reverse();
    let standardProofs = [...addFinalValue?.standardProofs]?.reverse();

    console.log({ standardRequirements })
    const standardRequirementList = standardRequirements?.map((item, indx) => ({
      title: item?.title,
      requirementId: item?.id,
      subStandardRequirements: item?.subStandardRequirements.map(item => ({
        ...item,
        subRequirementId: item?.id
      })),
      recommendation: item?.recommendation,
      standardRequirementClassificationId:
        item?.standardRequirementClassificationId,
      agencyStandardRequirements: addFinalValue?.standardRequirements?.[
        standardRequirements?.length - 1 - indx
      ]?.agencyStandardRequirements?.map((ele) => ({
        agencyId: ele.agencyId,
      })),
    }));

    standardProofs = standardProofs?.map((item, index) => ({
      ...item,
      agencyStandardProofs: addFinalValue?.standardProofs[
        addFinalValue?.standardProofs?.length - 1 - index
      ]?.agencyStandardProofs
        ?.map((val) => ({
          agencyId: val.id,
        }))
        .filter((item) => item?.agencyId),
      standardProofRequirements: addFinalValue?.standardProofs[
        addFinalValue?.standardProofs?.length - 1 - index
      ]?.standardProofRequirements
        ?.map((val) => {
          return {
            standardRequirementId: val.standardRequirementId ?? val.id,
          };
        })
        .filter((item) => item?.standardRequirementId),
    }));
     

    let pastSurveyStandardAssociations =
      addFinalValue?.pastSurveyStandardAssociations?.map((item) => {
        if (item?.id) return { pastSurveyStandardId: item?.id };
        return item;
      });

    pastSurveyStandardAssociations = pastSurveyStandardAssociations?.filter(
      (item) => item?.pastSurveyStandardId
    );
    

    setLoading(true);
    let res;
    try {
      if (!addFinalValue?.code.match(/^\d+\.\d+\.\d+$/)) {
        errorToast("تنسيق الكود على الطريقة 1.0.0");
      } else {
        res = await axiosPutRequest(environment.updateStandardProofRequirementRelation, {
          ...addFinalValue,
          standardRequirements: standardRequirementList,
          standardProofs,
          agencyStandards: [
            ...addFinalValue?.agencyStandards?.agencies,
            ...addFinalValue?.agencyStandards?.commonAgencies,
          ],
          pastSurveyStandardAssociations,
        });
      }
    } catch (err) {
      errorToast("حدث خطأ ");
    }

    setLoading(false);
    if (res.success) {
      successToast("تم تعديل المعيار بنجاح");
      history.push(
        `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`
      );
    }
  };

  console.log({ addFinalValue });

  return (
    <div className="my-8 mt-12 flex justify-between rounded-lg items-center flex-wrap gap-x-6 px-6 py-3 bg-white fixed bottom-6 left-20  shadow-xl">
      <div className="flex gap-2 mb-1">
        <p>
          {addFinalValue.standardRequirements?.length || "0"}{" "}
          <span className="text-light">متطلب مضاف ،</span>
        </p>
        <p>
          {addFinalValue.standardProofs?.length || "0"}{" "}
          <span className="text-light">مستند مضاف ،</span>
        </p>
        <p>
          {subRequirementCount || "0"}{" "}
          <span className="text-light">متطلب فرعى مضاف</span>
        </p>
      </div>
      <div className="text-end">
        <AppButton loading={loading} disabled={false} onClick={handleSubmit}>
          حفظ
        </AppButton>
      </div>
    </div>
  );
};

export default SubmitEditStandard;
