import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { InterlocutorView } from "../components/InterlocutorView";
import { Footer } from "../components/Footer";

export function Page13({ agencyTitle, globalStyles, perspectives }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    tableHead: {
      backgroundColor: "#2a2069",
      borderRadius: 8,
      textAlign: "right",
      paddingTop: 10,
      paddingRight: 10,
      fontSize: 14,
      color: "#fff",
      marginBottom: 8,
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      borderBottom: "solid",
      borderBottomWidth: 1,
      borderBottomColor: "#eee",
      justifyContent: "flex-end",
      gap: 10,
      paddingVertical: 8,
      marginTop: 8,
      paddingRight: 8,
    },
  });

  return (perspectives?.perspectivesList || [])?.map((perspective) => (
    <Page
      style={{
        ...styles.page,
        paddingBottom: 60,
      }}
      author="test"
      wrap={false}
    >
      <>
        <Text style={{ ...styles.sectionTitle }}>05. النتيجة التفصيلية </Text>
        <Text style={styles.sectionSecondaryTitle}>
          النتائج التفصيلية – منظور {perspective.title}
        </Text>
        <Text style={{ ...styles.subtitle }}>
          {" "}
          نستعرض أدناه النتائج التفصيلية للجهة بالمعايير في {perspective.title}
          :​
        </Text>
      </>
      {perspective?.interlocutors?.reverse()?.map((interlocutor) => (
        <InterlocutorView interlocutor={interlocutor} styles={styles} />
      ))}
      <Text
        fixed
        style={{
          position: "absolute",
          bottom: 40,
          right: 50,
          color: "#2A2069",
          fontSize: 7,
        }}
      >
        {"يمكنكم الاطلاع على الملاحظات من خلال نظام قياس"
          .split(" ")
          .map((word) => (
            <Text>{" " + word}</Text>
          ))}
      </Text>

      <Footer agencyTitle={agencyTitle} />
    </Page>
  ));
}
