import { useContext, useEffect, useState } from "react";
import { AddStandardContext } from "../../addStandardContext";
import { AppInput, DropdownMultiSelect } from "../../../../../../../components";
import AppButton from "../../../../../../../components/buttons/appButton";
import AppAccordion from "../../../../../../../components/appAccordion";
import { produce } from "immer";

const StandardSubRequirementItem = ({
  id,
  index,
  title,
  standardRequirementClassificationId,
  recommendation,
  requirementIndx,
  number,
  disabled,
}) => {
  const [subRequirement, setSubRequirement] = useState({
    id,
    title,
    standardRequirementClassificationId,
    recommendation,
  });
  const {
    requirmentList,
    setRequirmentList,
    classifications,
    setSubRequirementCount,
    setAddFinalValue,
  } = useContext(AddStandardContext);
  const [selectedClassifications, setSelectedClassifications] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const classificationOptions =
    classifications?.length > 0
      ? classifications?.map((item) => ({
          label: item.title,
          value: item.id,
          id: item.id,
        }))
      : [];

  const handleChangeClassifications = (value) => {
    setSelectedClassifications(value);

    setSubRequirement({
      ...subRequirement,
      standardRequirementClassificationId: value?.id ? value?.id : "",
    });
  };

  useEffect(() => {
    const selectedClass = classificationOptions?.filter(
      (item) => item?.id == standardRequirementClassificationId
    );
    setSelectedClassifications(selectedClass?.[0]);
  }, []);

  const handleChange = (e) => {
    setSubRequirement({ ...subRequirement, [e.target.name]: e.target.value });

    // setAddFinalValue(
    //   produce((draft) => {
    //     draft[e.target.name] = e.target.value;
    //   })
    // );
  };

  const handleSubmit = () => {
    setAddFinalValue(
      produce((draft) => {
        draft.standardRequirements[requirementIndx].subStandardRequirements[
          index
        ] = subRequirement;
      })
    );
    setIsOpen(false);
  };

  const handleDeleteClick = () => {
    setSubRequirementCount((prev) => prev - 1);
    setAddFinalValue(
      produce((draft) => {
        const deleteItem = draft.standardRequirements[
          requirementIndx
        ].subStandardRequirements.filter(
          (item, itemIndex) => itemIndex != index
        );
        draft.standardRequirements[requirementIndx].subStandardRequirements =
          deleteItem;
      })
    );
  };

  return (
    <div className="mt-4">
      <AppAccordion
        number={number}
        title={title}
        variant="purple"
        handleDeleteClick={handleDeleteClick}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        <div className=" p-5 pb-7 rounded-lg">
          <h2 className="font-semibold mb-4">تعديل المتطلب الفرعى</h2>

          <div className="row gap-y-3">
            <div className="col-md-6 ">
              <AppInput
                label="عنوان"
                placeholder="اكتب هنا"
                name="title"
                value={subRequirement.title}
                onChange={handleChange}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedClassifications}
                handleChange={handleChangeClassifications}
                label="تصنيف"
                placeholder="اكتب هنا"
                options={classificationOptions}
                isMultiple={false}
                disabled={disabled}
              />
            </div>
            {/* <div className="col-md-6 col-xl-3">
          <AppInput

            label="وزن"
            placeholder="اكتب هنا"
            name="recommendation"
            value={subRequirement.recommendation}
            onChange={handleChange}
          />
        </div> */}
            {/* <div className="col-md-6 col-xl-3">
              <AppInput label="محدد" placeholder="اكتب هنا" />
            </div> */}
          </div>
          <div className="row gap-y-4 mt-8">
            <div className="col-xl-9">
              <AppInput
                label="التوصية"
                placeholder="اكتب هنا"
                name="recommendation"
                value={subRequirement.recommendation}
                onChange={handleChange}
                disabled={disabled}
              />
            </div>
            <div className="col-xl-3 align-self-end">
              <div className="text-end">
                {/* <AppButton onClick={handleSubmit}>تعديل</AppButton> */}
                <AppButton onClick={handleSubmit}>تعديل التصنيف</AppButton>
              </div>
            </div>
          </div>
        </div>
      </AppAccordion>
    </div>
  );
};

export default StandardSubRequirementItem;
