import { getColorByValue } from "../../../../../helpers/utils";
import { isAgency } from "../../../../../helpers/isPermission";

const DigitalTransformationResult = ({ perspectives, qiyasObj }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-full p-4">
        <h1 className="font-bold text-2xl text-[#7c32c9]">
          نتيجة التحول الرقمي
        </h1>

        <div className="flex items-center justify-between gap-10 p-2 my-6 border border-[#7C32C9] rounded-md">
          <div className="text-[#2a2069] text-left mb-2">
            نتيجة التحول الرقمي
          </div>
          <div
            className="w-[73%] text-white pb-4 pt-2 text-center border border-[#7C32C9] rounded-md font-bold text-2xl"
            style={{ backgroundColor: getColorByValue(qiyasObj?.finalResult) }}
          >
            {qiyasObj?.finalResult?.toFixed(2)}%
          </div>
        </div>

        {!isAgency && (
          <>
            <div className="flex justify-between items-center mb-4 px-3 text-[#2a2069] font-medium">
              <span>المنظور</span>
              <span>النتيجة</span>
            </div>

            <div className="space-y-2">
              {perspectives?.perspectivesList?.map((perspective, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between py-1 px-3 border border-[#7C32C9] rounded-md"
                >
                  <div className="w-[40%] text-[#2a2069] mb-2">
                    {perspective.title}
                  </div>
                  <div class="w-full relative h-3 bg-[#f2f2f2] rounded-full">
                    <div
                      class={`absolute h-full rounded-full`}
                      style={{
                        width: `${perspective.resut}%`,
                        backgroundColor: "#7C32C9",
                      }}
                    ></div>
                  </div>
                  <div className="w-[10%] text-[#2a2069] text-left mb-2">
                    {perspective.resut.toFixed(2)}%
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DigitalTransformationResult;
