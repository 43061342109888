import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { cleanText } from "../../../../helpers/utils";
import { fixText } from "../AgencyAnswerReport";

export function Footer({ agencyTitle }) {
  const styles = StyleSheet.create({
    footerContainer: {
      position: "absolute",
      bottom: 24,
      left: 50,
      right: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "gray",
      fontSize: 6,
      fontWeight: 300,
      zIndex: 99999,
    },
    right: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 7,
    },
    spread: {
      marginLeft: 10,
      marginRight: 10,
    },
  });

  const cleanText = (input) => {
    return input.normalize("NFKC").replace(/\s+/g, " ").trim();
  };

  return (
    <View style={styles.footerContainer}>
      <Text>{fixText(agencyTitle)}</Text>
      <View style={styles.right}>
        <Text>قياس التحول الرقمي 2024</Text>
        <Text style={styles.spread}>|</Text>
        <Text render={({ pageNumber }) => `${pageNumber}`} />
      </View>
    </View>
  );
}
