import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { programsIcon } from "../../../../../assets/icons/Index";
import DownloadButton from "../../../../../components/DownloadButton";
import NewTable from "../../../../../components/NewTable";
import useExcelExport from "../../../../../helpers/customHooks/useExcelExport";
import { useDebounce } from "../../../../../hooks/useDebounce";
import useFileDownloader from "../../../../../hooks/useFileDownloader";
import HeaderWithBreadcrumbLayout from "../../../../../layouts/headerWithBreadcrumbLayout";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";

function CurrentAnswers() {
  const { agencyId } = useParams();
  const { state } = useLocation();

  const [oldAnswers, setOldAnswers] = useState([]);
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [oldAnswersLoading, setOldAnswersLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(oldAnswers || []);
  const [searchValue, setSearchValue] = useState("", 400);
  const [sort, setSort] = useState({});
  const [sortByStandardCode, setSortByStandardCode] = useState("asc");

  const debouncedAgencySearchKeyWord = useDebounce(
    searchValue,
    500
  );

  const getOldAnswers = async (page) => {
    setOldAnswersLoading(true);
    const res = await axiosGetRequest(environment.getAgencyAnswerByAgencyIdAttachments, {
      AgencyId: agencyId,
      SkipCount: page * 30,
      MaxResultCount: 30,
      Keyword: debouncedAgencySearchKeyWord,
      OrderByStandardCode: sortByStandardCode === "asc" ? "0" : "1",
    });

    if (res?.success) {
      setOldAnswers(res?.result?.items);
      setCount(res?.result?.totalCount);
    }
    setOldAnswersLoading(false);
  };

  useEffect(() => {
    getOldAnswers(0);
  }, [debouncedAgencySearchKeyWord, sortByStandardCode]);

  useEffect(() => {
    setFilteredData(oldAnswers);
  }, [oldAnswers]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getOldAnswers(newOffset);
  };

  const { downloadPdf } = useFileDownloader();

  const handleDownload = async (filePath, fileName) => {
    const api = `${process.env.REACT_APP_URL}/Reports/DawnloadAgencyOldFileByName`;
    const params = { fileName: filePath };

    await downloadPdf(api, params, fileName, null);
  };

  const columns = [
    {
      name: "اسم الملف",
      selector: (row) => row.name,
      header: "اسم الملف",
      accessorKey: "name",
      sortable: true,
    },
    {
      name: "اسم المعيار",
      selector: (row) => row.standardTitle,
      header: "اسم الملف",
      accessorKey: "name",
      sortable: true,
    },
    {
      name: "رقم المعيار",
      selector: (row) => row.standardCode,
      header: "رقم المعيار",
      accessorKey: "standardCode",
      sortable: true,
      center: true,
    },
    {
      name: "تحميل الملف",
      selector: (row) => row.name,
      header: "اسم الملف",
      accessorKey: "name",
      sortable: true,
      center: true,
      cell: (row) => (
        <DownloadButton onClick={() => handleDownload(row.filePath, row.name)}>
          تحميل الملف
        </DownloadButton>
      ),
    },
  ];

  // search
  const handleFilterData = (value) => {
    setSearchValue(value);
  };

  // excel
  const downloadExcel = useExcelExport();

  const dataForExcel = filteredData?.map((ele) => {
    return {
      "اسم الملف": ele.name,
      "رقم المعيار": ele.standardCode,
    };
  });

  const breadcumb = [
    {
      icon: programsIcon,
      title: "التقرير النهائي",
      url: "/previousSurveys",
    },
    {
      title: `اٍجابة جهة ${state?.agencyName}`,
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={breadcumb}>
      <div className="p-5 ">
        <NewTable
          withSearch
          withExcel
          onExportExcel={() =>
            downloadExcel(` ملفات قياس 2024_${agencyId}`, dataForExcel)
          }
          onSearch={handleFilterData}
          loading={oldAnswersLoading}
          columns={columns}
          data={filteredData}
          pagination={true}
          totalPages={Math.ceil(count / 30)}
          onPageChange={handlePageClick}
          currentPage={currentPage}
          handleSort={(col, order) => {
            if (col.accessorKey === "standardCode") {
              setSortByStandardCode(prev => prev === "asc" ? "desc" : "asc");
            }
          }}
        />
      </div>
    </HeaderWithBreadcrumbLayout>
  );
}

export default CurrentAnswers;
