import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";

const SCALE_ITEMS = [
  { label: "لا ينطبق", style: "scaleItemFourth", color: "#555" },
  { label: "عدم التزام", style: "scaleItemFirst", color: "#B40002" },
  { label: "التزام جزئي", style: "scaleItemThird", color: "#FF9D00" },
  { label: "التزام كلّي", style: "scaleItemSecond", color: "#1DC183" },
];

export function Page11({ agencyTitle, globalStyles, perspectives }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    perspectivesContainer: {
      width: "100%",
    },
    perspectivesNameContainer: {
      width: 100,
      height: "57",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 8,
      fontWeight: "bold",
      textAlign: "center",
      color: "white",
      backgroundColor: "#7C32C9",
      border: "1px solid #7C32C9",
      borderRadius: 5,
    },
    interluctorsContainer: {
      width: "80%",
      height: "100%",
      textAlign: "center",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 1,
    },
    interlocutor: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      fontSize: 6,
      backgroundColor: "#FBF9FE",
      border: "1px solid #D2CEF0",
      borderRadius: 5,
      paddingTop: 4,
      paddingBottom: 0,
    },
  });

  return (
    <Page style={styles.page} author="test">
      <Text style={{ ...styles.sectionTitle, marginTop: 20, marginBottom: 0 }}>
        05. النتيجة التفصيلية
      </Text>
      <Text style={{ ...styles.sectionSecondaryTitle, marginBottom: 0 }}>
        {" "}
        النتائج التفصيلية​
      </Text>

      <Text style={{ ...styles.subtitle, marginTop: 0 }}>
        نستعرض أدناه مدى التزام الجهة بجميع المعايير:
      </Text>

      <View
        style={{
          fontSize: 8,
          textAlign: "center",
          width: 95,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#7C32C9",
          border: "1px solid #7C32C9",
          borderRadius: 5,
          marginLeft: "auto",
          paddingTop: 5,
        }}
      >
        <Text>المنظور</Text>
      </View>

      <View style={styles.perspectivesContainer}>
        {perspectives?.perspectivesList?.map((perspective) => (
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "flex-start",
              gap: 1,
              marginVertical: 2,
            }}
          >
            <View
              style={{
                ...styles.perspectivesNameContainer,
                paddingTop: 4,
              }}
            >
              <Text>{perspective.title}</Text>
            </View>
            <View
              style={{
                ...styles.interlocutor,
                backgroundColor: "#E5D6F5",
                width: 30,
                paddingVertical: 0,
              }}
            >
              <View
                style={{
                  borderBottom: "1px solid #D2CEF0",
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "#CBACEA",
                  paddingTop: 4,
                  borderRadius: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 6,
                    fontWeight: "bold",
                    color: "#393071",
                  }}
                >
                  المحور
                </Text>
              </View>
              <Text
                style={{
                  fontSize: 6,
                  fontWeight: "bold",
                  color: "#393071",
                  paddingTop: "45%",
                }}
              >
                المعيار
              </Text>
            </View>
            <View style={styles.interluctorsContainer}>
              {perspective.interlocutors.reverse().map((interlocutor) => (
                <View style={styles.interlocutor}>
                  <View
                    style={{ borderBottom: "1px solid #D2CEF0", width: "100%" }}
                  >
                    <Text
                      style={{
                        fontSize: 6,
                        fontWeight: "bold",
                        color: "#393071",
                      }}
                    >
                      {interlocutor.title}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      textAlign: "center",
                      gap: 1,
                      paddingHorizontal: 1,
                      marginTop:
                        perspective.interlocutors.length === 1 ||
                        (perspective.interlocutors.length === 2 &&
                          interlocutor.standards.length <= 7) ||
                        (perspective.interlocutors.length === 3 &&
                          interlocutor.standards.length <= 4)
                          ? 10
                          : 5,
                    }}
                  >
                    {interlocutor.standards.sort(sortByCode).map(
                      (standard) =>
                        standard.surveyExpertAnswer && (
                          <View
                            style={{
                              backgroundColor: `${getBackGroundColor(
                                standard.surveyExpertAnswer.finalCommitmentLevel
                                  .title,
                                standard.surveyExpertAnswer.finalCommitmentLevel
                                  ?.color
                              )}`,
                              borderRadius: 4,
                              marginBottom: 1,
                              width: 25,
                            }}
                          >
                            <Text
                              style={{
                                color: "#fff",
                                fontSize: 7,
                                textAlign: "center",
                                top: 4,
                              }}
                            >
                              {standard.code}
                            </Text>
                          </View>
                        )
                    )}
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        {SCALE_ITEMS.map((item) => (
          <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Text style={{ color: "#2A2069", fontSize: 8 }}>{item.label}</Text>
            <View
              style={{
                width: 6,
                height: 6,
                backgroundColor: `${item.color}`,
                borderRadius: 2,
              }}
            ></View>
          </View>
        ))}
      </View>

      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}

function getBackGroundColor(title, color) {
  return color;
}

function sortByCode(a, b){
  const aNumber = parseFloat(a.code.split(".").join(""));
  const bNumber = parseFloat(b.code.split(".").join(""));

  return aNumber - bNumber; 
}
