import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { ScaleItem } from "../components/ScaleItem";
import { Footer } from "../components/Footer";
import { Break } from "../components/Break";
import {
  AGENCIES_ENUM,
  fixText,
  INCLUDED_AGENCIES_ENUM,
} from "../AgencyAnswerReport";

const SCALE_ITEMS = [
  { text: "الإبداع", style: "scaleItemLast", color: "#085133" },
  { text: "التكامل", style: "scaleItemSecond", color: "#1DC183" },
  { text: "التحسين", style: "scaleItemThird", color: "#FF9D00" },
  { text: "الإتاحة", style: "scaleItemFourth", color: "#FF6B02" },
  { text: "البناء", style: "scaleItemFirst", color: "#B40002" },
];

function numberToFixed(num = 0, d = 2) {
  const number = Number.isFinite(num) ? Number(num) : 0;
  return number.toFixed(d) + "%";
}

export function Page7({ qiyasObj, agencyTitle, globalStyles, agencyResults }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  const Equivalent_Result = [
    {
      percentage:
        (agencyResults?.ebdaaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.ebdaaStageCount,
      label: "الإبداع",
      style: "scaleItemLast",
      color: "#085133",
    },
    {
      percentage:
        (agencyResults?.takamolStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.takamolStageCount,
      label: "التكامل",
      style: "scaleItemSecond",
      color: "#1DC183",
    },
    {
      percentage:
        (agencyResults?.tahseenStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.tahseenStageCount,
      label: "التحسين",
      style: "scaleItemThird",
      color: "#FF9D00",
    },
    {
      percentage:
        (agencyResults?.etahaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.etahaStageCount,
      label: "الإتاحة",
      style: "scaleItemFourth",
      color: "#FF6B02",
    },
    {
      percentage:
        (agencyResults?.benaaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.benaaStageCount,
      label: "البناء",
      style: "scaleItemFirst",
      color: "#B40002",
    },
  ];

  const cleanText = (input) => {
    if (input.includes("ئ"))
      return input.normalize("NFKC").replace(/\s+/g, " ").trim();
    else return input.normalize("NFKD").replace(/\s+/g, " ").trim();
  };

  return (
    <Page style={styles.page} author="test">
      <View
        style={{
          ...styles.sectionTitle,
          marginTop: 40,
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
        }}
      >
        <View>
          <Text>.03 </Text>
        </View>
        <View
          style={{
            width: "98%"
          }}
        >
          <Text>
            ملخص نتيجة
            <Text> </Text>
            {fixText(agencyTitle)}
          </Text>
        </View>
      </View>

      <Text style={styles.subtitle}>
        نستعرض أدناه نتيجة التحول الرقمي لـ ({" "}
        {!INCLUDED_AGENCIES_ENUM.includes(agencyTitle)
          ? agencyTitle
          : fixText(agencyTitle, true)}{" "}
        ) ونتيجة الالتزام بالمعايير {agencyTitle === "جامعة الباحة" ? "الـأساسية" : "الأساسية" } للتحول الرقمي 2024، إضافة إلى نتيجة
        كل منظور، وذلك استنادا على البيانات والمعلومات المدخلة عبر نظام قياس.
      </Text>

      <Break />

      <Text style={styles.sectionSecondaryTitle}>نتيجة التحول الرقمي</Text>
      <View style={styles.progressHeaderContainer}>
        <Text style={{ ...styles.progressHeaderLabel, marginRight: 150 }}>
          النتيجة
        </Text>
      </View>

      <View style={styles.totalScore}>
        <Text style={styles.totalScoreLabel}>نتيجة التحول الرقمي للجهة</Text>
        <View style={styles.totalScoreTextWrapper}>
          <Text style={styles.totalScoreText}>
            {qiyasObj?.finalResult?.toFixed(2)} %
          </Text>
        </View>
      </View>

      <Break />

      <View>
        <Text style={{ ...styles.sectionSecondaryTitle, fontSize: 12 }}>
          {" "}
          النتيجة المكافئة
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "70%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  opacity: qiyasObj?.finalResult >= 90 ? 1 : 0,
                }}
              >
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2024 قياس
                </Text>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.finalResult)}
                </Text>
                <View style={styles.indicatorArrowUp}></View>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  opacity:
                    qiyasObj?.finalResult >= 80 && qiyasObj?.finalResult < 90
                      ? 1
                      : 0,
                }}
              >
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2024 قياس
                </Text>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.finalResult)}
                </Text>
                <View style={styles.indicatorArrowUp}></View>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  opacity:
                    qiyasObj?.finalResult >= 40 && qiyasObj?.finalResult < 80
                      ? 1
                      : 0,
                }}
              >
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2024 قياس
                </Text>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.finalResult)}
                </Text>
                <View style={styles.indicatorArrowUp}></View>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  opacity:
                    qiyasObj?.finalResult >= 10 && qiyasObj?.finalResult < 40
                      ? 1
                      : 0,
                }}
              >
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2024 قياس
                </Text>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.finalResult)}
                </Text>
                <View style={styles.indicatorArrowUp}></View>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  opacity: qiyasObj?.finalResult < 10 ? 1 : 0,
                }}
              >
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2024 قياس
                </Text>
                <Text style={{ color: "#2A2069", fontSize: 10 }}>
                  {numberToFixed(qiyasObj?.finalResult)}
                </Text>
                <View style={styles.indicatorArrowUp}></View>
              </View>
            </View>

            <View style={styles.scale}>
              {SCALE_ITEMS.slice().map((item, index) => (
                <ScaleItem key={index} style={styles[item.style]}>
                  {item.text}
                </ScaleItem>
              ))}
            </View>

            {!qiyasObj?.isFirstTimeInQiyas && <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  marginTop: 6,
                  opacity: qiyasObj?.previousFinalResult >= 90 ? 1 : 0,
                }}
              >
                <View style={styles.indicatorArrowDown}></View>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.previousFinalResult)}
                </Text>
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2023 قياس
                </Text>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  marginTop: 6,
                  opacity:
                    qiyasObj?.previousFinalResult >= 80 &&
                      qiyasObj?.previousFinalResult < 90
                      ? 1
                      : 0,
                }}
              >
                <View style={styles.indicatorArrowDown}></View>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.previousFinalResult)}
                </Text>
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2023 قياس
                </Text>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  marginTop: 6,
                  opacity:
                    qiyasObj?.previousFinalResult >= 40 &&
                      qiyasObj?.previousFinalResult < 80
                      ? 1
                      : 0,
                }}
              >
                <View style={styles.indicatorArrowDown}></View>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.previousFinalResult)}
                </Text>
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2023 قياس
                </Text>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  marginTop: 6,
                  opacity:
                    qiyasObj?.previousFinalResult >= 10 &&
                      qiyasObj?.previousFinalResult < 40
                      ? 1
                      : 0,
                }}
              >
                <View style={styles.indicatorArrowDown}></View>
                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.previousFinalResult)}
                </Text>
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2023 قياس
                </Text>
              </View>
              <View
                label="indicator"
                style={{
                  textAlign: "center",
                  marginTop: 6,
                  opacity: qiyasObj?.previousFinalResult < 10 ? 1 : 0,
                }}
              >
                <View style={styles.indicatorArrowDown}></View>

                <Text
                  style={{
                    color: "#2A2069",
                    fontSize: 10,
                    margin: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {numberToFixed(qiyasObj?.previousFinalResult)}
                </Text>
                <Text
                  style={{ fontSize: 10, margin: "auto", fontWeight: "bold" }}
                >
                  2023 قياس
                </Text>
              </View>
            </View>
            }
          </View>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 30,
        }}
      >
        {Equivalent_Result.map((item) => (
          <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Text style={{ color: "#2A2069", fontSize: 8 }}>{item.label}</Text>
            <View
              style={{
                width: 6,
                height: 6,
                backgroundColor: `${item.color}`,
                borderRadius: 2,
              }}
            ></View>
          </View>
        ))}
      </View>

      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}
