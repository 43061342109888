import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import "moment/locale/ar";
import Slide1 from "../assets/Slide1.jpg";
import { cleanText } from "../../../../helpers/utils";
import { fixText } from '../AgencyAnswerReport';

export function IntroPage({ agencyTitle, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    arabicText: {
      width: "55%",
      color: "#4CD4B0",
      fontSize: 18,
      textAlign: "right",
      position: "absolute",
      top: "65%",
      right: 95,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      unicodeBidi: "isolate-override",
    },
    dateText: {
      color: "#575757",
      fontSize: 17,
      textAlign: "right",
      position: "absolute",
      bottom: 72,
      left: 70,
      fontWeight: "semibold",
      fontFamily: "DiodrumArabic",
      direction: "rtl",
    },
  });
  // const cleanText = (input) => {
  //   return input.normalize("NFKC").replace(/\s+/g, " ").trim();
  // };
  // function fixFinallllly(text) {
  //   return text.normalize("NFKD").replace(/\s+/g, " ").trim()+ '\u200B'
  // }
  // function fixText(text) {
  //   return (text.normalize("NFKC").replace(/\s+/g, " ").trim() + '\u200B').replace("الاتصالات", "الإتصالات")
  // }
  return (
    <Page size="A4">
      <Image src={Slide1} />
      <View style={styles.arabicText}>
        <Text>{fixText(agencyTitle)}</Text>
      </View>
      <Text style={styles.dateText}>
        {"15"} {"ديسمبر 2024م"}
      </Text>
    </Page>
  );
}
